import React from 'react';
import { useUILanguage } from 'hooks/useUILanguage';
import { FooterFinnish } from './FooterFinnish';
import { FooterSwedish } from './FooterSwedish';

export const AreenaFooter: React.FC = () => {
  const language = useUILanguage();

  switch (language) {
    case 'fi':
      return <FooterFinnish />;
    case 'sv':
      return <FooterSwedish />;
  }
};
