import { Control } from '@yleisradio/areena-types';
import React from 'react';
import FavoriteOffIcon from 'assets/favoriteOffIcon.svg';
import FavoriteOnIcon from 'assets/favoriteOnIcon.svg';
import ShareIcon from 'assets/shareIcon.svg';
import DownloadIcon from 'assets/downloadIcon.svg';
import logger from 'services/logger';
import Grid from 'assets/grid.svg';
import Globe from 'assets/globe.svg';
import SearchIcon from 'assets/search.svg';
import PlayIcon from 'assets/playIcon.svg';
import ChevronLeft from 'assets/chevronLeft.svg';

export function useIcon(
  control: Control
): React.ComponentType<React.SVGProps<SVGSVGElement>> | undefined {
  if (control.icon) {
    switch (control.icon.id) {
      case 'icon-favorite-off':
        return FavoriteOffIcon;
      case 'icon-favorite-on':
        return FavoriteOnIcon;
      case 'share':
        return ShareIcon;
      case 'icon-download':
        return DownloadIcon;
      case 'grid':
        return Grid;
      case 'globe':
        return Globe;
      case 'search':
        return SearchIcon;
      case 'icon-play':
        return PlayIcon;
      case 'chevron-left':
        return ChevronLeft;
      default:
        logger.debug(`No icon for control with id ${control.icon.id}`);
    }
  }
  return undefined;
}
