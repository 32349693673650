import { Notification as NotificationType } from '@yleisradio/areena-types';
import React, { ComponentType } from 'react';
import { Notification, NotificationStyle } from './Notification';
import styles from './Notifications.module.scss';

export type NotificationComponent = {
  notification?: NotificationType;
  children?: React.ReactNode;
};

interface Props {
  notifications: NotificationType[];
  notificationStyle?: NotificationStyle;
  notificationComponent?: ComponentType<NotificationComponent> | undefined;
}

export const Notifications: React.FunctionComponent<Props> = ({
  notifications: allNotifications,
  notificationStyle,
  notificationComponent,
}) => {
  const notifications = allNotifications.filter((n) => !!n.uiMessage);

  if (notifications.length === 0) {
    return null;
  }

  const NotificationComponent = notificationComponent
    ? notificationComponent
    : Notification;

  if (notifications.length === 1) {
    const notification = notifications[0] as NotificationType;
    return (
      <NotificationComponent
        notificationStyle={notificationStyle}
        notification={notification}
      />
    );
  }
  return (
    <span className={styles.list}>
      {notifications.map((notification, i) => (
        <span key={i}>
          <NotificationComponent
            notificationStyle={notificationStyle}
            notification={notification}
          />
        </span>
      ))}
    </span>
  );
};
