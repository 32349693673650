import { BaseResponse, Notification } from '@yleisradio/areena-types';

export class FetchError extends Error {
  notifications: Notification[] | undefined;

  constructor(
    message: string,
    {
      originalError,
      response,
    }: { originalError?: unknown; response?: BaseResponse | null | undefined }
  ) {
    if (originalError) {
      super(message, { cause: originalError });
    } else {
      super(message);
    }

    this.name = this.constructor.name;
    this.notifications = response?.notifications;
  }
}

export class NotFoundError extends FetchError {}
