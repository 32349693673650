import {
  Control,
  ControlResponse,
  Notification,
  Pointer,
  Reference,
} from '@yleisradio/areena-types';
import useSWR, { KeyedMutator } from 'swr';
import { getControl } from 'services/areena-api/fetchers';
import { useLocationParameters } from './useLocationParameters';
import { useRefresher } from './useRefresher';

type FetcherParameters = [
  pointer: Pointer,
  locationParameters: Record<string, string>,
];
type Key = FetcherParameters | null;
type Fetcher = (args: FetcherParameters) => Promise<ControlResponse | null>;

const fetcher: Fetcher = ([pointer, locationParameters]) =>
  getControl(pointer, locationParameters);

export function useReference(reference: Reference | null): {
  control: Control | null;
  loading: boolean;
  mutateControl: KeyedMutator<ControlResponse | null>;
  notifications: Notification[];
} {
  const locationParameters = useLocationParameters();
  const key: Key =
    reference && locationParameters
      ? [reference.destination, locationParameters]
      : null;
  const refresh = useRefresher();

  const { data, mutate } = useSWR(key, fetcher, {
    onSuccess(data) {
      refresh({ data, mutate });
    },
  });
  const { data: control = null, notifications = [] } = data || {};
  const loading = !!reference && !data;
  return { control, loading, mutateControl: mutate, notifications };
}
