import {
  AgeRestrictionLabel,
  ContentRatingReasonLabel,
  Label,
} from '@yleisradio/areena-types';
import Image from 'next/image';
import { useUILanguage } from 'hooks/useUILanguage';
import { contentRatingImgUrl, defaultLoader } from 'utils/cloudinary';

export const isRestrictionLabel = (
  label: Label
): label is ContentRatingReasonLabel | AgeRestrictionLabel =>
  ['contentRating.reason', 'contentRating.ageRestriction'].includes(label.type);

interface LabelProps {
  label?: Label;
}

export const ContentRatingImage: React.FunctionComponent<LabelProps> = ({
  label,
}) => {
  const language = useUILanguage();
  if (label && isRestrictionLabel(label) && label.raw) {
    const imageId =
      label.type === 'contentRating.ageRestriction'
        ? `ageRestriction-${label.raw}_${language}`
        : label.raw;
    return (
      <Image
        key={imageId}
        loader={defaultLoader}
        src={contentRatingImgUrl(imageId)}
        width={20}
        height={20}
        alt={label.formatted || label.raw}
        priority
      />
    );
  }
  return null;
};
