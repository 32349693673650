import { useCallback, useState } from 'react';
import logger from 'services/logger';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

type SetState<T> = (value: T | ((prevState: T) => T)) => void;

// Source: https://usehooks.com/useLocalStorage/
export const useLocalStorage = <T>(
  key: string | undefined,
  initialValue: T
): [T, SetState<T>] => {
  const [storedValue, setStoredValue] = useState<T>(initialValue);

  useIsomorphicLayoutEffect((): void => {
    if (!key) return;

    try {
      const item = window.localStorage.getItem(key);

      if (item) setStoredValue(JSON.parse(item));
    } catch (error) {
      logger.error(error, 'Error getting local storage value.');
    }
  }, [key]);

  const setValue: SetState<T> = useCallback(
    (value) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);

        if (!key) {
          throw new Error('Key is required for setting local storage');
        }

        if (valueToStore === undefined) {
          window.localStorage.removeItem(key);
        } else {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        logger.error(error, 'Error setting local storage value.');
      }
    },
    [key, storedValue]
  );

  return [storedValue, setValue];
};
