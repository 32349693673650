import { Notification } from '@yleisradio/areena-types';

export class ErrorWithNotifications extends Error {
  notifications: Notification[] | undefined;

  constructor(
    message: string,
    notifications: Notification[] | undefined,
    cause?: unknown
  ) {
    if (cause) {
      super(message, { cause });
    } else {
      super(message);
    }

    this.notifications = notifications;

    // see https://www.typescriptlang.org/docs/handbook/2/classes.html#inheriting-built-in-types
    Object.setPrototypeOf(this, ErrorWithNotifications.prototype);
  }
}
