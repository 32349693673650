import React from 'react';
import classNames from 'classnames';
import styles from './AreenaFooter.module.scss';
import YleAreenaLogo from 'assets/areena.svg';
import YleLogo from 'assets/yle.svg';
import BarnensArenanLogo from 'assets/barnensArena.svg';
import { serviceRoutes } from 'utils/routes';
import { useAreenaService } from 'contexts/AreenaServiceContext';
import { useTranslation } from 'hooks/useTranslation';
import { Container } from 'components/Container';
import { getCanonicalBaseURL, getOtherLanguageURL } from 'utils/url';
import { Control } from 'components/Controls/Control';
import { DSLink } from 'components/DSLink';
import { Link } from 'components/Link/Link';
import { useRouter } from 'next/router';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

export const FooterSwedish: React.FunctionComponent = () => {
  const { areenaService } = useAreenaService();
  const t = useTranslation();
  const { asPath: path, query } = useRouter();

  const headingId = 'footer-heading';

  const finnishAlternativeUrl = getOtherLanguageURL(
    path,
    query,
    'sv',
    areenaService
  );

  return (
    <footer aria-labelledby={headingId} className={styles.footer}>
      <VisuallyHidden asChild>
        <h2 id={headingId}>{t('footer')}</h2>
      </VisuallyHidden>
      <Container>
        <div className={classNames(styles.areenaLinksSection)}>
          <Link
            className={styles.areenaLink}
            lang="fi"
            pointer={{
              uri: finnishAlternativeUrl,
            }}
          >
            <YleAreenaLogo aria-hidden className={styles.areenaLogo} />
            <div className={styles.areenaLinkText}>
              {t('otherLanguageLink')}
            </div>
          </Link>

          <Link
            className={styles.areenaLink}
            pointer={{
              uri: `${getCanonicalBaseURL('sv')}/${serviceRoutes['children']['sv']}`,
            }}
          >
            <BarnensArenanLogo aria-hidden className={styles.areenaLogo} />
            <div className={styles.areenaLinkText}>{t('lastenAreena')}</div>
          </Link>
        </div>
        <div className={styles.generalLinksSection}>
          <div className={styles.subLinksSection}>
            <h3 className={styles.subTitle}>{t('footerSupportTitle')}</h3>
            <ul className={styles.linksContainer}>
              <li>
                <DSLink
                  pointer={{
                    uri: `https://svenska.yle.fi/s/10046447`,
                    type: 'external-html',
                  }}
                  text={t('tvApps')}
                  textSize="s"
                />
              </li>
              <li>
                <DSLink
                  pointer={{
                    uri: `https://svenska.yle.fi/s/10046434`,
                    type: 'external-html',
                  }}
                  text={t('androidApps')}
                  textSize="s"
                />
              </li>
              <li>
                <DSLink
                  pointer={{
                    uri: `https://svenska.yle.fi/s/10046444`,
                    type: 'external-html',
                  }}
                  text={t('iPhoneAndiPad')}
                  textSize="s"
                />
              </li>
              <li>
                <DSLink
                  pointer={{
                    uri: `https://svenska.yle.fi/arenan/instruktioner`,
                    type: 'external-html',
                  }}
                  text={t('allInstructions')}
                  textSize="s"
                />
              </li>
            </ul>
          </div>
          <div className={styles.subLinksSection}>
            <h3 className={styles.subTitle}>{t('footerSomeTitle')}</h3>
            <ul className={styles.linksContainer}>
              <li>
                <DSLink
                  pointer={{
                    uri: `https://www.facebook.com/yleareena`,
                    type: 'external-html',
                  }}
                  text={t('facebook')}
                  textSize="s"
                />
              </li>
              <li>
                <DSLink
                  pointer={{
                    uri: `https://www.instagram.com/yleareena`,
                    type: 'external-html',
                  }}
                  text={t('instagram')}
                  textSize="s"
                />
              </li>
              <li>
                <DSLink
                  pointer={{
                    uri: `https://www.facebook.com/svenskayle`,
                    type: 'external-html',
                  }}
                  text={t('arenanFacebook')}
                  textSize="s"
                />
              </li>
              <li>
                <DSLink
                  pointer={{
                    uri: `https://www.instagram.com/svenskayle`,
                    type: 'external-html',
                  }}
                  text={t('arenanInstagram')}
                  textSize="s"
                />
              </li>
            </ul>
          </div>
          <div className={classNames(styles.subLinksSection)}>
            <h3 className={styles.subTitle}>{t('footerFeedbackTitle')}</h3>
            <ul className={styles.linksContainer}>
              <li>
                <DSLink
                  pointer={{
                    uri: `https://kundservice.yle.fi/csp`,
                    type: 'external-html',
                  }}
                  text={t('customerService')}
                  textSize="s"
                />
              </li>
            </ul>
          </div>
        </div>
      </Container>
      <div className={styles.yleFiLinkContainer}>
        <Container>
          <Link
            pointer={{ uri: `https://svenska.yle.fi`, type: 'external-html' }}
            className={styles.yleFiLink}
          >
            <div className={styles.yleFiLinkContent}>
              <YleLogo className={styles.yleLogo} />
              <span className={styles.yleLinkText}>{t('yleUrl')}</span>
            </div>
          </Link>
        </Container>
      </div>
      <div className={styles.yleRelatedLinkContainer}>
        <Container>
          <ul className={styles.yleRelatedLinks}>
            <li>
              <DSLink
                pointer={{
                  uri: `https://svenska.yle.fi/bolaget`,
                  type: 'external-html',
                }}
                text={t('yleCorporation')}
                textSize="s"
                theme="light"
              />
            </li>
            <li>
              <DSLink
                pointer={{
                  uri: `https://svenska.yle.fi/kontaktuppgifter`,
                  type: 'external-html',
                }}
                text={t('yleContactInfo')}
                textSize="s"
                theme="light"
              />
            </li>
            <li>
              <DSLink
                pointer={{
                  uri: `https://svenska.yle.fi/s/cookiepolicy`,
                  type: 'external-html',
                }}
                text={t('cookiePolicy')}
                textSize="s"
                theme="light"
              />
            </li>
            <li>
              <Control
                as={'link'}
                controlKey={'cookieSettings'}
                control={{
                  type: 'activator',
                  title: t('cookieSettings'),
                  tag: 'cookie-consent',
                  functionality: {
                    id: 'cookie-consent',
                  },
                }}
              />
            </li>
            <li>
              <DSLink
                pointer={{
                  uri: `https://svenska.yle.fi/s/dataskyddsbeskrivning`,
                  type: 'external-html',
                }}
                textSize="s"
                theme="light"
                text={t('privacyPolicy')}
              />
            </li>
          </ul>
        </Container>
      </div>
    </footer>
  );
};
