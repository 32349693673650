import { Card, Label, PickLabel, LabelType } from '@yleisradio/areena-types';
import { idFromPointer } from './pointer';
import { getFormattedTime } from 'utils/time';

export function findLabel<PickedLabelType extends Label['type']>(
  labels: Label[] | undefined,
  type: PickedLabelType
): PickLabel<PickedLabelType> | undefined {
  return (
    labels &&
    labels.find(
      (label): label is PickLabel<PickedLabelType> => label.type === type
    )
  );
}

export function filterLabelsByType<Type extends Label['type']>(
  type: Type,
  labels: Label[] | undefined
): PickLabel<Type>[] {
  return labels
    ? labels.filter((label): label is PickLabel<Type> => label.type === type)
    : [];
}

export function getFormattedLabels(labels: Label[] | undefined): Label[] {
  return labels ? labels.filter((label) => !!label.formatted) : [];
}

function getMultipleLabels(card: Card, wantedLabels: LabelType[]) {
  const cardLabels =
    (card.labels &&
      card.labels.filter((label) => wantedLabels.includes(label.type))) ||
    [];
  return cardLabels;
}

export function getCardKey(card: Card, listKey: string): string {
  return `${listKey}-${card.title}-${card.pointer?.uri}-${card.description}`;
}

export function getCardProgressIds(cards: Card[] | undefined): string[] {
  if (!cards) return [];
  return cards.reduce<string[]>((acc, card) => {
    const progressLabel = findLabel(card.labels, 'progress');
    if (progressLabel && progressLabel.pointer) {
      const id = idFromPointer(progressLabel.pointer);
      if (id) return [...acc, id];
    }
    return acc;
  }, []);
}

export function getHistoryLabelsForCard(
  card: Card,
  historyEntries: Card[]
): Label[] {
  const progressLabel = findLabel(card.labels, 'progress');
  const cardId =
    progressLabel &&
    progressLabel.pointer &&
    idFromPointer(progressLabel.pointer);
  const relevantHistoryLabels: LabelType[] = ['watchedDuration', 'finished'];
  const matchingCard = historyEntries.find(
    (entry) => idFromPointer(entry.pointer) === cardId
  );
  const historyEntryLabels = matchingCard
    ? getMultipleLabels(matchingCard, relevantHistoryLabels)
    : [];

  return historyEntryLabels;
}

export function getLabelKey(label: Label, cardKey: string): string {
  return `${cardKey}-${label.type}-${label.raw}-${label.formatted}`;
}

/**
 * Label['formatted'] has "klo" prefix. Use this util when only the date is needed.
 * @param labels
 * @returns {{broadcastEndDate: Date | undefined, broadcastStartDate: Date | undefined}}
 */
export const getBroadcastDates = (
  labels?: Label[]
): {
  broadcastStartDate: Date | undefined;
  broadcastEndDate: Date | undefined;
} => {
  const broadcastStartDateLabel = findLabel(labels, 'broadcastStartDate');
  const broadcastStartDate =
    typeof broadcastStartDateLabel?.raw === 'string'
      ? new Date(broadcastStartDateLabel.raw)
      : undefined;

  const broadcastEndDateLabel = findLabel(labels, 'broadcastEndDate');

  const broadcastEndDate =
    typeof broadcastEndDateLabel?.raw === 'string'
      ? new Date(broadcastEndDateLabel.raw)
      : undefined;

  return { broadcastStartDate, broadcastEndDate };
};

export const getProgramTitleWithStartTime = (card?: Card): string => {
  if (!card) return '';

  const startTime = findLabel(card.labels, 'broadcastStartDate');

  if (!startTime?.raw) return card.title ?? '';

  const programStartDate = new Date(startTime.raw);

  const ongoingProgramStartTime = getFormattedTime(programStartDate);

  return `${ongoingProgramStartTime} ${card.title}`;
};
