import React from 'react';
import classNames from 'classnames';
import styles from './Grid.module.scss';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const smallSizes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const;
type SmallSize = (typeof smallSizes)[number];
type SmallOrder = SmallSize;

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const largeSizes = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24,
] as const;
type LargeSize = (typeof largeSizes)[number];
type LargeOrder = LargeSize;

interface Props {
  children?: React.ReactNode;
  mobile?: SmallSize;
  mobileLandscape?: SmallSize;
  tablet?: SmallSize;
  tabletLandscape?: SmallSize;
  desktopSmall?: LargeSize;
  desktop?: LargeSize;
  desktopXL?: LargeSize;
  orderMobile?: SmallOrder;
  orderMobileLandscape?: SmallOrder;
  orderTablet?: SmallOrder;
  orderTabletLandscape?: SmallOrder;
  orderDesktopSmall?: LargeOrder;
  orderDesktop?: LargeOrder;
  orderDesktopXL?: LargeOrder;
  htmlTag?: keyof JSX.IntrinsicElements;
}

/**
 * A column or cell intended to be used inside a GridContainer-component.
 *
 * Size values (mobile, desktop etc.) tell how many columns the GridItem takes
 * up space in each breakpoint. For example mobile value of 3 should take space
 * of three columns out of 12 columns.
 *
 * Mobile sizes have 12 columns, and desktops sizes have 24 columns.
 *
 * If any mobile size is defined without desktop sizes defined, the largest
 * given mobile size will double its size in desktop breakpoints. This way
 * the component retains its proportions in both mobile and desktops sizes
 * without extra parameters. For example mobile value of 3 would take 6
 * columns of space in desktop sizes, when no desktop values are set.
 */
export const GridElement: React.FC<Props> = ({
  children,
  mobile,
  mobileLandscape,
  tablet,
  tabletLandscape,
  desktopSmall,
  desktop,
  desktopXL,
  orderMobile,
  orderMobileLandscape,
  orderTablet,
  orderTabletLandscape,
  orderDesktopSmall,
  orderDesktop,
  orderDesktopXL,
  htmlTag,
}) => {
  const smallSizes = [mobile, mobileLandscape, tablet, tabletLandscape];

  const largestSmallSize =
    smallSizes.reverse().find((size) => size !== undefined) || 0;
  const scaledLargestSmallSize = largestSmallSize * 2;
  const isScalingSmallToLargeSize =
    scaledLargestSmallSize > 0 && desktopSmall === undefined;

  const className = classNames(
    styles.gridItem,

    mobile !== undefined && styles[`mobile${mobile}`],
    mobileLandscape !== undefined &&
      styles[`mobileLandscape${mobileLandscape}`],
    tablet !== undefined && styles[`tablet${tablet}`],
    tabletLandscape !== undefined &&
      styles[`tabletLandscape${tabletLandscape}`],
    isScalingSmallToLargeSize &&
      styles[`desktopSmall${scaledLargestSmallSize}`],
    desktopSmall !== undefined && styles[`desktopSmall${desktopSmall}`],
    desktop !== undefined && styles[`desktop${desktop}`],
    desktopXL !== undefined && styles[`desktopXL${desktopXL}`],

    orderMobile !== undefined && styles[`orderMobile${orderMobile}`],
    orderMobileLandscape !== undefined &&
      styles[`orderMobileLandscape${orderMobileLandscape}`],
    orderTablet !== undefined && styles[`orderTablet${orderTablet}`],
    orderTabletLandscape !== undefined &&
      styles[`orderTabletLandscape${orderTabletLandscape}`],
    orderDesktopSmall !== undefined &&
      styles[`orderDesktopSmall${orderDesktopSmall}`],
    orderDesktop !== undefined && styles[`orderDesktop${orderDesktop}`],
    orderDesktopXL !== undefined && styles[`orderDesktopXL${orderDesktopXL}`]
  );

  const Tag = htmlTag || 'div';

  return <Tag className={className}>{children}</Tag>;
};
