import React, { useContext, useMemo, useRef } from 'react';
import logger from 'services/logger';

type Invalidator = () => void;

type Value = {
  readonly addInvalidator: (invalidator: Invalidator) => void;
  readonly deleteInvalidator: (invalidator: Invalidator) => void;
  readonly invalidate: Invalidator;
};

// Not exported on purpose, use Provider component and hook below
const ProgressCacheInvalidatorContext = React.createContext<Value>({
  addInvalidator: () => {},
  deleteInvalidator: () => {},
  invalidate: () => {
    throw new Error(
      "Can't invalidate user history as UserHistoryInvalidatorContext doesn't have a Provider"
    );
  },
});

export const ProgressCacheInvalidatorProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const invalidatorsRef = useRef<Set<Invalidator>>(new Set());
  const value = useMemo<Value>(
    () => ({
      addInvalidator: (f) => {
        invalidatorsRef.current.add(f);
      },
      deleteInvalidator: (f) => {
        invalidatorsRef.current.delete(f);
      },
      invalidate: () => {
        invalidatorsRef.current.forEach((f) => f());
        logger.debug(
          `Progress caches were invalidated by calling ${invalidatorsRef.current.size} invalidator(s).`
        );
      },
    }),
    []
  );
  return (
    <ProgressCacheInvalidatorContext.Provider value={value}>
      {children}
    </ProgressCacheInvalidatorContext.Provider>
  );
};

export function useProgressCacheInvalidator(): Value {
  return useContext(ProgressCacheInvalidatorContext);
}
