import React, { useContext } from 'react';

const ReferrerContext = React.createContext<string | undefined>(undefined);

export const ReferrerProvider: React.FC<
  React.PropsWithChildren<{ referrer: string | undefined }>
> = ({ children, referrer }) => {
  return (
    <ReferrerContext.Provider value={referrer}>
      {children}
    </ReferrerContext.Provider>
  );
};

export function useReferrer(): string | undefined {
  return useContext(ReferrerContext);
}
