import { Pointer } from '@yleisradio/areena-types';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'components/Link';
import styles from './Button.module.scss';

export type ButtonSize = 'lg' | 'md' | 'sm' | 'xs';
export type ButtonVariant = 'primary' | 'secondary' | 'text';
export type ButtonPadding = 'default' | 'vertical' | 'horizontal' | 'none';

type ButtonProps = {
  text: string;
  iconOnly?: boolean;
  disabled?: boolean;
  download?: boolean;
  iconLeft?: React.ComponentType<React.SVGProps<SVGSVGElement>> | undefined;
  iconRight?: React.ComponentType<React.SVGProps<SVGSVGElement>> | undefined;
  onClick?: React.MouseEventHandler | undefined;
  padding?: ButtonPadding | undefined;
  pointer?: Pointer | undefined;
  size?: ButtonSize | undefined;
  theme?: 'light' | undefined;
  variant?: ButtonVariant | undefined;
  width?: 'full' | undefined;
  textAlign?: 'left' | 'center' | undefined;
  dataTestId?: string | undefined;
} & React.AriaAttributes;

export const Button: React.FunctionComponent<ButtonProps> = ({
  text,
  disabled,
  download,
  iconLeft: IconLeft,
  iconOnly,
  iconRight: IconRight,
  onClick,
  padding = 'default',
  pointer,
  size = 'sm',
  theme,
  variant = 'primary',
  width,
  textAlign = 'center',
  dataTestId,
  ...props
}) => {
  const className = classNames(
    styles.button,
    styles[`size-${size}`],
    styles[`variant-${variant}`],
    styles[`padding-${padding}`],
    width && styles[`width-${width}`],
    theme === 'light' && styles.themeLight,
    styles[`text-align-${textAlign}`],
    disabled && styles.disabled,
    iconOnly && styles.iconOnly
  );

  if (pointer && !disabled) {
    return (
      <Link
        className={className}
        dataTestId={dataTestId}
        download={download}
        onClick={onClick}
        pointer={pointer}
        {...props}
        aria-label={props['aria-label'] || (iconOnly ? text : undefined)}
      >
        {IconLeft && <IconLeft aria-hidden className={styles.icon} />}
        {!iconOnly && <bdi className={styles.text}>{text}</bdi>}
        {IconRight && <IconRight aria-hidden className={styles.icon} />}
      </Link>
    );
  }
  return (
    <button
      className={className}
      data-testid={dataTestId}
      disabled={disabled || undefined}
      onClick={!disabled ? onClick : undefined}
      type="button"
      {...props}
      aria-label={props['aria-label'] || (iconOnly ? text : undefined)}
    >
      {IconLeft && <IconLeft aria-hidden className={styles.icon} />}
      {!iconOnly && <bdi className={styles.text}>{text}</bdi>}
      {IconRight && <IconRight aria-hidden className={styles.icon} />}
    </button>
  );
};
