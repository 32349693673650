import React, { FC, PropsWithChildren, SVGProps } from 'react';
import { Link } from 'components/Link';
import styles from 'components/Navigation/NavigationLink.module.scss';
import classNames from 'classnames';

type Props = {
  icon: FC<SVGProps<SVGAElement>>;
  activeIcon: FC<SVGProps<SVGAElement>>;
  isActive: boolean;
  to: string;
  hideMobileText?: boolean;
};

const NavigationLink: FC<PropsWithChildren<Props>> = ({
  icon: Icon,
  activeIcon: ActiveIcon,
  isActive,
  to,
  children,
  hideMobileText,
}) => {
  const linkClassNames = classNames(
    styles.link,
    hideMobileText && styles.iconOnlyLink,
    isActive && styles.activeLink
  );
  const textClassNames = classNames(
    styles.linkText,
    hideMobileText && styles.textHidden
  );

  return (
    <Link
      pointer={{ uri: to }}
      aria-current={isActive}
      className={linkClassNames}
    >
      {isActive ? (
        <ActiveIcon className={styles.icon} aria-hidden />
      ) : (
        <Icon className={styles.icon} aria-hidden />
      )}

      <div className={classNames(textClassNames)}>{children}</div>
    </Link>
  );
};

export { NavigationLink };
