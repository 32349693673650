import { Language } from '@yleisradio/areena-types';
import { YleTunnusInstance } from 'types/yleTunnus';
import { isTestEnvironment } from 'utils/environment';

export async function initializeTunnus(
  language: Language
): Promise<YleTunnusInstance> {
  if (typeof window !== 'undefined' && window.yleTunnus) {
    const tunnus: YleTunnusInstance = await window.yleTunnus({
      trackingAppName: 'areena-web-items',
      initiatingApp: 'areena-web-items',
      language: language,
      returnToUrl: window.location.href,
      environment: isTestEnvironment() ? 'test' : 'production',
      theme: 'dark',
    });
    return tunnus;
  } else {
    throw new Error('yleTunnus was not found in window');
  }
}
