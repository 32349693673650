import { Card, ListStyle } from '@yleisradio/areena-types';
import { SearchHorizontalCard } from 'components/Card/SearchHorizontalCard';
import React from 'react';
import { getCardKey } from 'utils/card';
import { Observable as ImpressionObservable } from 'contexts/ImpressionAnalyticsContext/Observable';
import { idFromPointer } from 'utils/pointer';
import { ListItemMicrodata } from 'components/List/ListItemMicrodata';
import { GridElement } from 'components/Grid';

function getCardImageSizes(listStyle: ListStyle | undefined): string {
  if (!listStyle?.image || listStyle.image === '16:9') {
    return '(min-width: 1020px) 23vw, (min-width: 480px) 30vw, 45vw';
  } else {
    return '(min-width: 1400px) 15vw, (min-width: 800px) 18vw, (min-width: 640px) 23vw, (min-width: 480px) 26vw, 45vw';
  }
}

type Props = {
  listStyle: ListStyle | undefined;
  pageIndex: number;
  pageKey: string;
  pageSize: number;
  cards: Card[];
  onClick(): void;
};

const MegaMenuSearchListCardPage: React.FunctionComponent<Props> = ({
  listStyle,
  pageIndex,
  pageKey,
  pageSize,
  cards,
  onClick,
}) => {
  const cardImageSizes = getCardImageSizes(listStyle);

  return (
    <>
      {cards.map((card, cardIndex) => {
        const cardKey = getCardKey(card, pageKey);
        return (
          <GridElement
            key={cardKey}
            mobile={10}
            tablet={10}
            tabletLandscape={6}
            desktopSmall={11}
            desktopXL={12}
            htmlTag="li"
          >
            <ListItemMicrodata
              key={cardKey}
              itemIndex={pageIndex * pageSize + cardIndex}
              pointer={card.pointer}
              htmlTag="div"
            >
              <ImpressionObservable
                yleId={idFromPointer(card.pointer) || undefined}
                itemPosition={cardIndex}
              >
                <SearchHorizontalCard
                  card={card}
                  cardKey={cardKey}
                  listStyle={listStyle}
                  sizes={cardImageSizes}
                  onClick={onClick}
                />
              </ImpressionObservable>
            </ListItemMicrodata>
          </GridElement>
        );
      })}
    </>
  );
};

export default MegaMenuSearchListCardPage;
