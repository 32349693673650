import { Label as LabelType } from '@yleisradio/areena-types';
import React from 'react';
import { ContentRatingImage, isRestrictionLabel } from './ContentRatingImage';

const TIME_RAW_TYPES = ['date', 'duration'] as LabelType['rawType'][];

interface Props {
  label: LabelType;
}

export const Label: React.FunctionComponent<Props> = ({ label }) => {
  if (isRestrictionLabel(label)) {
    return <ContentRatingImage label={label} />;
  }
  if (TIME_RAW_TYPES.includes(label.rawType) && typeof label.raw == 'string') {
    return <time dateTime={label.raw}>{label.formatted}</time>;
  }
  return <>{label.formatted}</>;
};
