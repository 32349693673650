import { Control } from '@yleisradio/areena-types';

// Possible functionality IDs can be found at https://github.com/Yleisradio/areena-api/blob/master/app/fi/yle/areena/models/ui/attributes/Functionality.scala
export enum FunctionalityId {
  Login = 'login',
  Share = 'share',
  TunnusBeginIdentification = 'tunnus.begin-identification',
  ToggleQueue = 'queue',
  SwitchLanguage = 'switch-language',
  CookieConsent = 'cookie-consent',
}

export const isShareControl = (control: Control | undefined | null) =>
  control?.functionality?.id === FunctionalityId.Share;

export const getControlKey = (control: Control) => {
  const stringValues = Object.values(control)
    .filter((value) => typeof value === 'string')
    .join('-');

  return control.icon ? stringValues + '-' + control?.icon?.id : stringValues;
};
