import { useCallback, useEffect, useState } from 'react';

function getCookie(name: string): string | null {
  if (typeof window === 'undefined') return null;

  const cookies = document.cookie.split('; ');

  for (const cookie of cookies) {
    const [namePart, valuePart = ''] = cookie.split('=');
    if (namePart === name) return decodeURIComponent(valuePart);
  }

  return null;
}

const YEAR_IN_SECONDS = 3.1536e10;

function setCookie(name: string, value: string): void {
  if (typeof window === 'undefined') return;

  const expires = new Date(Date.now() + YEAR_IN_SECONDS).toUTCString();

  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; Expires=${expires}; Path=/`;
}

export function useCookie(
  name: string | null | undefined
): [value: string | null, setValue: (value: string) => void] {
  const [state, setState] = useState<string | null>(null);

  useEffect(() => {
    if (!name) return;

    const value = getCookie(name);
    if (typeof value === 'string') setState(value);
  }, [name]);

  const update = useCallback(
    (newValue: string) => {
      if (!name) throw new Error("Missing cookie's name");

      setState(newValue);
      setCookie(name, newValue);
    },
    [name]
  );

  return [state, update];
}
