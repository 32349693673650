import { Pointer } from '@yleisradio/areena-types';
import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { Link } from 'components/Link';
import styles from './AlternateButton.module.scss';

type Size = 'small' | 'large';
type TextAlignment = 'left' | 'center';

interface AlternateButtonProps extends React.AriaAttributes {
  pointer?: Pointer | undefined;
  text: string;
  Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>> | undefined;
  isDisabled?: boolean;
  isSelected?: boolean;
  onClick?: React.MouseEventHandler | undefined;
  size?: Size;
  textAlignment?: TextAlignment;
  dataTestId?: string | undefined;
}

export const AlternateButton: React.FunctionComponent<AlternateButtonProps> =
  forwardRef<HTMLElement, AlternateButtonProps>(function AlternateButton(
    {
      Icon,
      isDisabled,
      isSelected,
      onClick,
      pointer,
      text,
      size = 'small',
      textAlignment = 'left',
      dataTestId,
      ...rest
    },
    ref
  ) {
    const className = classNames(
      styles.root,
      Icon && styles.rootWithIcon,
      isDisabled && styles.rootDisabled,
      isSelected && styles.rootSelected,
      styles[size],
      styles[textAlignment]
    );
    const iconElement = Icon ? (
      <span className={styles.icon}>
        <Icon />
      </span>
    ) : null;

    if (pointer && !isDisabled) {
      return (
        <Link
          className={className}
          onClick={onClick}
          pointer={pointer}
          aria-current={isSelected}
          dataTestId={dataTestId}
          ref={ref}
          {...rest}
        >
          {iconElement}
          {text}
        </Link>
      );
    }
    return (
      <button
        className={className}
        aria-current={isSelected}
        disabled={isDisabled}
        onClick={onClick}
        data-testid={dataTestId}
        ref={ref as React.RefObject<HTMLButtonElement>}
        {...rest}
      >
        {iconElement}
        {text}
      </button>
    );
  });
