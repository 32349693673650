import React, { useContext, useMemo, useState } from 'react';

type Value = {
  isAvailable: boolean;
  setIsAvailable: (isAvailable: boolean) => void;
  hasErrored: boolean;
  setHasErrored: (hasErrored: boolean) => void;
};

const AnalyticsAvailabilityContext = React.createContext<Value>({
  isAvailable: false,
  setIsAvailable() {
    throw new Error(
      "Can't set availability because AnalyticsAvailabilityContext doesn't have a Provider"
    );
  },
  hasErrored: false,
  setHasErrored() {
    throw new Error(
      "Can't set error state because AnalyticsAvailibilityContext doesn't have a provider"
    );
  },
});

export const AnalyticsAvailabilityProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isAvailable, setAvailability] = useState<boolean>(false);
  const [hasErrored, setHasErrored] = useState(false);
  const value = useMemo<Value>(
    () => ({
      isAvailable,
      setHasErrored,
      hasErrored,
      setIsAvailable: setAvailability,
    }),
    [isAvailable, hasErrored]
  );
  return (
    <AnalyticsAvailabilityContext.Provider value={value}>
      {children}
    </AnalyticsAvailabilityContext.Provider>
  );
};

export function useAnalyticsAvailability(): Value {
  return useContext(AnalyticsAvailabilityContext);
}
