import * as React from 'react';
import { apmRum } from 'services/apm-rum';
import logger from 'services/logger';

type Props = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<Props, State> {
  override state: State = { hasError: false };

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  override componentDidCatch(error: Error): void {
    logger.error(error, 'Client error caught in ErrorBoundary');

    if (apmRum) {
      apmRum.captureError(error);
    }
  }

  override render(): React.ReactNode {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}
