import { useCallback, useState } from 'react';
import { useTunnusContext } from 'contexts/TunnusContext';
import logger from 'services/logger';
import { Action } from './type';

export function useLoginAction(): Action {
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);

  const { yleTunnusInstance } = useTunnusContext();

  const runAction = useCallback(async () => {
    if (yleTunnusInstance) {
      setIsLoginDialogOpen(true);
      try {
        await yleTunnusInstance.logIn();
      } catch (e) {
        logger.error(e, 'Error when initiating login');
      }
      setIsLoginDialogOpen(false);
    } else {
      logger.warn(
        new Error("Couldn't initiate login as yleTunnusInstance is undefined")
      );
    }
  }, [setIsLoginDialogOpen, yleTunnusInstance]);

  return {
    runAction,
    executing: isLoginDialogOpen,
  };
}
