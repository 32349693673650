import { useUILanguage } from 'hooks/useUILanguage';
import { useCallback, useEffect, useRef } from 'react';
import logger from 'services/logger';
import { eventStack } from './eventStack';
import { ImpressionEvent, ObservableLabels, Observe } from './types';
import { useCookies } from './useCookies';
import { useYleSk } from './useYleSk';

const SEND_INTERVAL = 5000;

type Props = {
  uiElementPosition: number;
  uiElementType: string;
};

export const useShowObserver = ({
  uiElementPosition,
  uiElementType,
}: Props): Observe => {
  const showObserverRef = useRef<IntersectionObserver>();
  const labelsForElementMapRef = useRef(new Map<Element, ObservableLabels>());
  const cookieAttributesRef = useCookies();
  const language = useUILanguage();
  const yle_sk = useYleSk();

  const createObserver = useCallback(() => {
    if (!window.IntersectionObserver) {
      logger.error('No IntersectionObserver support');
      return;
    }

    return new IntersectionObserver(
      (
        entries: IntersectionObserverEntry[],
        observer: IntersectionObserver
      ) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const element = entry.target;
            const observableLabels =
              labelsForElementMapRef.current.get(element);

            if (!observableLabels) {
              throw new Error('No labels for element');
            }

            const eventData: ImpressionEvent = {
              impression_type: 'show',
              impression_timestamp: Date.now(),
              yle_sk,
              yle_vrs_platform: 'desktop',
              yle_vrs_device: 'browser',
              yle_ui_language: language,
              service_id: 'areena',
              ui_element_position: uiElementPosition,
              ui_element_type: uiElementType,
              ...observableLabels,
              ...cookieAttributesRef.current,
            };
            eventStack.addEvent(eventData);
            observer.unobserve(element);
            labelsForElementMapRef.current.delete(element);
          }
        });
      }
    );
  }, [cookieAttributesRef, language, uiElementPosition, uiElementType, yle_sk]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      eventStack.sendEvents();
    }, SEND_INTERVAL);

    return () => {
      eventStack.sendEvents();
      clearInterval(intervalId);
    };
  }, [cookieAttributesRef, uiElementPosition, uiElementType, yle_sk, language]);

  const observe = useCallback(
    (element: HTMLDivElement, labels: ObservableLabels) => {
      const unobserve = () => {
        showObserverRef.current?.unobserve(element);
        labelsForElementMapRef.current.delete(element);
      };

      if (!showObserverRef.current) {
        showObserverRef.current = createObserver();
        if (!showObserverRef.current) return unobserve;
      }

      labelsForElementMapRef.current.set(element, labels);
      showObserverRef.current.observe(element);

      return unobserve;
    },
    [createObserver]
  );

  return observe;
};
