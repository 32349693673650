import logger from 'services/logger';
import React, { FC } from 'react';
import styles from './PlayerToast.module.scss';
import CheckMarkIcon from 'assets/check.svg';

type Props = {
  notificationText: string;
};

export const PlayerNotification: FC<Props> = ({ notificationText }) => {
  return (
    <span className={styles.toast}>
      <CheckMarkIcon aria-hidden className={styles.icon} />
      <span className={styles.text}>{notificationText}</span>
    </span>
  );
};

export const playerToast = async (notificationText: string) => {
  const { innerWidth: windowWidth } = window;
  const isTabletLandscape = windowWidth > 800;

  import('react-hot-toast')
    .then(({ toast }) =>
      toast(() => <PlayerNotification notificationText={notificationText} />, {
        duration: 5000,
        style: {
          marginBottom: isTabletLandscape ? '6.6rem' : '5.4rem',
          padding: 0,
          background: 'transparent',
        },
        position: isTabletLandscape ? 'bottom-right' : 'bottom-center',
        // When given the toast an id, new toasts replaces existing ones.
        id: 'playerToast',
      })
    )
    .catch((e) => {
      logger.error(e, 'react-hot-toast failed to load');
    });
};
