import { Service } from '@yleisradio/areena-types';
import { useTranslation } from 'hooks/useTranslation';
import React from 'react';

type ServiceResultCounts = {
  [key in Service]: number;
};

const useSearchNotification = (
  service: Service,
  serviceResultCounts: ServiceResultCounts
): React.ReactElement => {
  const t = useTranslation();

  switch (service) {
    case 'tv':
      return serviceResultCounts['radio'] ? (
        <>
          <span>{`${t('noSearchResultsFoundTV')}.`}</span>
          <span>
            {`${t('podcastsFound')} `}
            <b>
              {`${serviceResultCounts['radio']} 
                ${
                  serviceResultCounts['radio'] === 1
                    ? t('searchResult')
                    : t('searchResultsPlural')
                }.`}
            </b>
          </span>
        </>
      ) : (
        <span>{`${t('nothingFoundTV')}`}</span>
      );
    case 'radio':
      return serviceResultCounts['tv'] ? (
        <>
          <span>{`${t('noSearchResultsFoundPodcasts')}.`}</span>
          <span>
            {`${t('tvProgramsFound')} `}
            <b>
              {`${serviceResultCounts['tv']}
                ${
                  serviceResultCounts['tv'] === 1
                    ? t('searchResult')
                    : t('searchResultsPlural')
                }.`}
            </b>
          </span>
        </>
      ) : (
        <span>{`${t('nothingFoundPodcasts')}`}</span>
      );
    default:
      return <span>{`${t('noSearchResultsFound')}`}</span>;
  }
};

export default useSearchNotification;
