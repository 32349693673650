import { FC } from 'react';
import { Link } from 'components/Link';
import { Control, Service } from '@yleisradio/areena-types';
import styles from 'components/CategoryLinkControl/CategoryLinkControl.module.scss';

type Props = {
  control: Control;
  onClick(): void;
  service: Service;
};

const CategoryLinkControl: FC<Props> = ({ control, onClick, service }) => {
  if (!control.destination) return null;
  return (
    <Link
      pointer={control.destination}
      className={styles.link}
      onClick={onClick}
      service={service}
    >
      {control.title}
    </Link>
  );
};

export { CategoryLinkControl };
