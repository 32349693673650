import { useReferrer } from 'contexts/ReferrerContext';
import React, { useContext, useMemo } from 'react';
import { Labels } from 'services/yleAnalyticsSdk';

type ContextValue = Labels | undefined;

// Not exported on purpose, use Provider component and hook below
const AnalyticsContext = React.createContext<ContextValue>(undefined);

export const AnalyticsContextProvider: React.FunctionComponent<{
  context: Labels | undefined;
  children: React.ReactNode;
}> = ({ context: contextLabels, children }) => {
  const referrer = useReferrer();

  const value: ContextValue = useMemo(
    () =>
      contextLabels && {
        ...(referrer && { ns_referrer: referrer }),
        ...contextLabels,
      },
    [contextLabels, referrer]
  );

  if (!value) {
    return <>{children}</>;
  }

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export function useAnalyticsContext(): ContextValue {
  return useContext(AnalyticsContext);
}
