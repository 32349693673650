import { Control } from '@yleisradio/areena-types';
import React from 'react';
import { AlternateButton } from '..';
import Icon from 'assets/chevronLeft.svg';

interface BackLinkProps {
  control: Control;
  onClick?: React.MouseEventHandler | undefined;
  dataTestId?: string | undefined;
}

export const BackLink: React.FunctionComponent<BackLinkProps> = ({
  control,
  onClick,
  dataTestId,
}) => {
  return control.title ? (
    <AlternateButton
      pointer={control.destination}
      text={control.title}
      isDisabled={!!control.disabled}
      Icon={Icon}
      onClick={onClick}
      dataTestId={dataTestId}
    />
  ) : null;
};
