type Props = {
  className?: string | undefined;
  id?: string;
  children: React.ReactNode;
  htmlTag?: keyof JSX.IntrinsicElements;
};

export const ItemListMicroData = ({
  className,
  id,
  children,
  htmlTag,
}: Props) => {
  const Tag = htmlTag || 'ul';
  return (
    <Tag
      className={className}
      id={id}
      itemScope
      itemType="https://schema.org/ItemList"
    >
      {children}
    </Tag>
  );
};
