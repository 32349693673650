import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import classNames from 'classnames';
import { useTunnusContext } from 'contexts/TunnusContext';
import { FC } from 'react';
import styles from './UserInitials.module.scss';
import { useTranslation } from 'hooks/useTranslation';

type Props = {
  size: 'sm' | 'md';
};

export const UserInitials: FC<Props> = ({ size }) => {
  const t = useTranslation();
  const { userInformation } = useTunnusContext();
  const initials = userInformation?.initials;

  return (
    <figure
      className={classNames(
        styles.initials,
        size === 'md' && styles.initialsSizeMd
      )}
      hidden={initials === undefined}
    >
      <VisuallyHidden asChild>
        <figcaption>{t('usersInitials')}</figcaption>
      </VisuallyHidden>

      {initials}
    </figure>
  );
};
