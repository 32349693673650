import { Control, ControlResponse } from '@yleisradio/areena-types';
import logger from 'services/logger';
import { useLoginAction } from './actions/useLoginAction';
import { useShareAction } from './actions/useShareAction';
import { isActivator, isNavigator, isOperator } from './typeGuards';
import { OperationState } from './type';
import { usePlayerActionForControl } from './actions/usePlayerAction';
import { useOperatorAction } from './actions/useOperatorAction';
import { FunctionalityId } from 'utils/control';
import { useBeginIdentificationAction } from './actions/useBeginIdentificationAction';
import { Action } from './actions/type';
import { KeyedMutator } from 'swr';
import { useToggleItemInQueueAction } from './actions/useToggleItemInQueueAction';
import { useServiceAction } from './actions/useServiceAction';
import { useCookieConsentAction } from './actions/useCookieConsentAction';

export function useAction({
  control,
  mutateControl,
  isShareDialogOpen,
  setIsShareDialogOpen,
  setOperationState,
}: {
  control: Control | null;
  mutateControl: KeyedMutator<ControlResponse | null>;
  isShareDialogOpen: boolean | undefined;
  setIsShareDialogOpen: ((value: boolean) => void) | undefined;
  setOperationState: ((newState: OperationState) => void) | undefined;
}): Action | null {
  const loginAction = useLoginAction();
  const beginIdentificationAction = useBeginIdentificationAction();
  const shareAction = useShareAction({
    control,
    isShareDialogOpen,
    setIsShareDialogOpen,
  });
  const playerAction = usePlayerActionForControl({
    control,
  });
  const operatorAction = useOperatorAction({
    control,
    setOperationState,
    mutateControl,
  });
  const serviceAction = useServiceAction(control);

  const toggleItemInQueueAction = useToggleItemInQueueAction({ control });

  const cookieConsentAction = useCookieConsentAction();

  if (!control) {
    return null;
  }

  if (isActivator(control)) {
    switch (control.functionality.id) {
      case FunctionalityId.Login:
        return loginAction;
      case FunctionalityId.Share:
        return shareAction;
      case FunctionalityId.TunnusBeginIdentification:
        return beginIdentificationAction;
      case FunctionalityId.ToggleQueue:
        return toggleItemInQueueAction;
      case FunctionalityId.CookieConsent:
        return cookieConsentAction;

      default:
        return {
          runAction: () => {
            logger.debug(
              `Tried to activate unknown functionality "${control.functionality.id}"`
            );
          },
          executing: false,
        };
    }
  }

  if (isNavigator(control)) {
    if (control.destination.type === 'player') {
      return playerAction;
    } else if (control.destination.type === 'service') {
      return serviceAction;
    }
    return null;
  }
  if (isOperator(control)) {
    return operatorAction;
  }
  return null;
}
