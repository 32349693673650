import { useCallback } from 'react';
import logger from 'services/logger';
import { Action } from './type';
import { useCookieConsent } from 'contexts/CookieConsentContext';
import { showConsentModal } from 'services/yleConsentSdk';

export function useCookieConsentAction(): Action {
  const consent = useCookieConsent();

  const runAction = useCallback(() => {
    if (consent) {
      try {
        showConsentModal();
      } catch (e) {
        logger.error(e, 'Error when opening consentSDK modal');
      }
    } else {
      logger.warn(
        new Error("Couldn't open cookie consent modal as consent is undefined")
      );
    }
  }, [consent]);

  return {
    runAction,
    executing: false,
  };
}
