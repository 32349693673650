import React from 'react';
import { useTranslation } from 'hooks/useTranslation';

import styles from './SkipLink.module.scss';

export const SkipLink: React.FunctionComponent = () => {
  const t = useTranslation();
  return (
    <a className={styles.skipLink} href="#maincontent">
      {t('skipToContent')}
    </a>
  );
};
