import { fetchWithTimeout } from 'utils/fetch';
import { getUrlWithAuthentication } from './common';
import { locationsApiProperties } from './properties/frontend';

export interface Location {
  country_code?: string;
  is_portability_region?: boolean;
}

const { urlBase } = locationsApiProperties;

const locationsApiTimeout = 8000;

function getAuthenticatedUrl(url: string): string {
  const { appId, appKey } = locationsApiProperties;
  return getUrlWithAuthentication(url, appId, appKey);
}

export async function getCurrentLocation(): Promise<Location> {
  if (typeof window == 'undefined') {
    throw new Error('getCurrentLocation only works in browser');
  }

  const res = await fetchWithTimeout(locationsApiTimeout)(
    getAuthenticatedUrl(`${urlBase}/v3/address/current`)
  );

  if (res.ok) {
    return await res.json();
  } else {
    throw new Error(
      `Locations API responsed with HTTP ${res.status} ${res.statusText}`
    );
  }
}
