import React, { FC } from 'react';
import styles from './SearchNotification.module.scss';
import { NotificationComponent } from '../Notifications';

const SearchNotification: FC<NotificationComponent> = ({
  notification,
  children,
}) => {
  return (
    <div className={styles.root}>
      {notification && !children ? <span>{notification.uiMessage}</span> : null}
      {!notification && children}
    </div>
  );
};

export { SearchNotification };
