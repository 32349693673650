import dynamic from 'next/dynamic';
import { WrapperComponent } from './type';

const ShareDialog = dynamic(() => import('components/ShareDialog'), {
  ssr: false,
});

export const ShareWrapper: WrapperComponent = ({
  children,
  control,
  isShareDialogOpen,
  setIsShareDialogOpen,
  shareDialogId,
}) => {
  const hide = () => setIsShareDialogOpen(false);

  return control ? (
    <span className="ShareWrapper">
      {children}
      <ShareDialog
        control={control}
        hide={hide}
        isVisible={isShareDialogOpen}
        shareDialogId={shareDialogId}
      />
    </span>
  ) : null;
};
