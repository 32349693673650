import {
  Activator,
  Control,
  Navigator,
  Operator,
  Reference,
} from '@yleisradio/areena-types';

export function isActivator(c: Control | undefined | null): c is Activator {
  return !!c && c.type === 'activator' && !!c.functionality;
}

export function isNavigator(c: Control | undefined | null): c is Navigator {
  return !!c && c.type === 'navigator' && !!c.destination;
}

export function isOperator(c: Control | undefined | null): c is Operator {
  return !!c && c.type === 'operator' && !!c.operation;
}

export function isReference(c: Control | undefined | null): c is Reference {
  return !!c && c.type === 'reference' && !!c.destination;
}
