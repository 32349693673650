import { useUILanguage } from 'hooks/useUILanguage';
import Script from 'next/script';
import React, { useContext, useState } from 'react';
import { apmRum } from 'services/apm-rum';
import logger from 'services/logger';
import { yleConsentSdkUrl } from 'services/properties/frontend';
import * as YleConsentSdk from 'services/yleConsentSdk';
import { Consent } from 'types/yleConsentSdk';

// Not exported on purpose, use Provider component and hook below
const CookieConsentContext = React.createContext<Consent | null>(null);

export const CookieConsentContextProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const language = useUILanguage();
  const [state, setState] = useState<Consent | null>(null);

  const onConsentChange = (consent: Consent) => {
    setState(consent);
  };

  return (
    <CookieConsentContext.Provider value={state}>
      <Script
        src={yleConsentSdkUrl}
        type="module"
        onLoad={() => {
          try {
            YleConsentSdk.init({ language, onConsentChange });
          } catch (e) {
            logger.error(e, "yle-consent-sdk couldn't be initialized");

            if (
              apmRum &&
              e instanceof Error &&
              !(e instanceof YleConsentSdk.ConsentSdkNotAvailableError)
            ) {
              apmRum.captureError(e);
            }
          }
        }}
      />

      {children}
    </CookieConsentContext.Provider>
  );
};

export function useCookieConsent(): Consent | null {
  return useContext(CookieConsentContext);
}
