import { Control, Pointer } from '@yleisradio/areena-types';
import { useState } from 'react';
import { usePlayerState } from 'contexts/PlayerStateContext';
import { getAvailablePlayerByPointer } from 'services/areena-api/fetchers';
import logger from 'services/logger';
import { Action } from './type';
import { toastPlaybackError } from 'components/Notifications';
import { useUILanguage } from 'hooks/useUILanguage';

type PlayerActionForControlProps = {
  control: Control | null;
};

export function usePlayerActionForControl({
  control,
}: PlayerActionForControlProps): Action {
  const pointer = control?.destination;

  return usePlayerActionForPointer({ pointer });
}

type PlayerActionForPointerProps = {
  pointer: Pointer | undefined;
};

export function usePlayerActionForPointer({
  pointer,
}: PlayerActionForPointerProps): Action {
  const { setActivePlayer, updateRecommendationsSource } = usePlayerState();
  const [isExecuting, setIsExecuting] = useState(false);
  const language = useUILanguage();

  async function playerAction() {
    if (isExecuting) {
      return;
    }

    if (!pointer) {
      logger.error(new Error('Pointer needs to be defined'));
      void toastPlaybackError(language);
      return;
    }

    setIsExecuting(true);

    getAvailablePlayerByPointer(pointer)
      .then((player) => {
        setIsExecuting(false);
        if (!player) {
          logger.debug('No available player, closing player');
        }
        setActivePlayer(player || undefined);

        if (player?.media.type === 'AudioObject') {
          updateRecommendationsSource(player || undefined);
        }
      })
      .catch(() => {
        setIsExecuting(false);
        setActivePlayer(undefined);
        void toastPlaybackError(language);
      });
  }
  return {
    runAction: playerAction,
    executing: false,
  };
}
