import {
  Audience,
  Card,
  Content,
  Control,
  Grouping,
  HighlightCard,
  List,
  View,
} from '@yleisradio/areena-types';
import { deepClone } from './deep-clone';

type AudienceContent = HighlightCard | List | Control | Grouping;
type AudienceKey = keyof Audience;

const SUPPORTED_AUDIENCES: AudienceKey[] = ['dvrSupport', 'authenticated'];

//https://docs.google.com/document/d/1vWk4Y2YhvB8z_FJRX9qDtJuAAfIFdJsjWlDdrrhP3DA/edit#heading=h.40a96rn5nviz
const filterByAudience = (
  supportedAudiences: AudienceKey[],
  content: AudienceContent,
  uiState: Audience
): boolean => {
  const audiences = content.audience;
  if (!audiences) return true;
  const keys = Object.keys(audiences) as AudienceKey[];
  if (keys) {
    return keys.every((key) => {
      if (!supportedAudiences.includes(key)) {
        return audiences[key] === true ? false : true;
      }
      return (uiState[key] || false) === audiences[key];
    });
  } else {
    return true;
  }
};

const filterContent = <T extends AudienceContent[] | undefined>(
  supportedAudiences: AudienceKey[],
  contentArray: T,
  isAuthenticated: boolean
): T => {
  if (!contentArray) return contentArray;
  const audienceState: Audience = {
    authenticated: isAuthenticated,
    dvrSupport: true,
  };
  return contentArray.filter((content) => {
    return filterByAudience(supportedAudiences, content, audienceState);
  }) as T;
};

export const filterViewByAudience = (
  data: View,
  isAuthenticated: boolean
): View => {
  const filteredData: View = deepClone(data);

  if (filteredData.tabs) {
    filteredData.tabs = filteredData.tabs.map((tab) => {
      if (tab.content)
        tab.content = filterContent(
          SUPPORTED_AUDIENCES,
          tab.content,
          isAuthenticated
        );
      return tab;
    });
  }
  if (filteredData.header && filteredData.header.controls) {
    const filteredHeader = { ...filteredData.header };
    filteredHeader.controls =
      filterContent(
        SUPPORTED_AUDIENCES,
        filteredData.header.controls,
        isAuthenticated
      ) || [];
    filteredData.header = filteredHeader;
  }
  return filteredData;
};

export const filterCardByAudience = (
  card: Card,
  isAuthenticated: boolean
): Card => {
  const filteredCard = deepClone(card);

  if (filteredCard.controls)
    filteredCard.controls = filterContent(
      SUPPORTED_AUDIENCES,
      filteredCard.controls,
      isAuthenticated
    );

  return filteredCard;
};

export const isHighlightCard = (c: Content | undefined): c is HighlightCard =>
  !!c && c.type === 'highlightCard';

export const isList = (c: Content | undefined): c is List =>
  !!c && c.type === 'list';

export const isListArray = (cl: Content[] | undefined): cl is List[] =>
  !!cl && cl.every((c) => isList(c));

export function createHighlightCardKey(
  card: HighlightCard,
  index: number
): string {
  return `${card.type}${index}${card.title}`;
}
