import { Control, Language, Pointer, Service } from '@yleisradio/areena-types';
import { useUILanguage } from 'hooks/useUILanguage';
import logger from 'services/logger';
import { pointerToCanonicalURL } from 'utils/pointer';
import { Action } from './type';
import { useAreenaService } from 'contexts/AreenaServiceContext';

function isNativeShareAllowed(): boolean {
  return /Android|iPhone|iPad|iPod/i.test(window.navigator.userAgent);
}

function getShareData(
  pointer: Pointer | undefined,
  language: Language,
  service: Service | undefined
): { title: string; url: string } {
  const {
    title,
    location: { origin, pathname },
  } = window.document;

  return {
    title,
    url:
      (pointer && pointerToCanonicalURL(pointer, language, service)) ||
      origin + pathname,
  };
}

interface Props {
  control: Control | null;
  isShareDialogOpen: boolean | undefined;
  setIsShareDialogOpen: ((value: boolean) => void) | undefined;
}

export function useShareAction({
  control,
  isShareDialogOpen,
  setIsShareDialogOpen,
}: Props): Action {
  const language = useUILanguage();
  const pointer = control?.destination;
  const { areenaService } = useAreenaService();

  async function share() {
    if (!setIsShareDialogOpen) {
      logger.error(new Error('setIsShareDialogOpen needs to be defined'));
      return;
    }
    const toggleShareDialog = () => {
      setIsShareDialogOpen(!isShareDialogOpen);
    };
    if (navigator.share && isNativeShareAllowed()) {
      try {
        await navigator.share(getShareData(pointer, language, areenaService));
      } catch (e) {
        if ((e as DOMException).name !== 'AbortError') {
          logger.warn(e, 'Error while running navigator.share');
          toggleShareDialog();
        }
      }
    } else {
      toggleShareDialog();
    }
  }

  return {
    runAction: share,
    executing: false,
  };
}
