import logger from './logger';

export function getUrlWithAuthentication(
  apiUrl: string,
  appId: string,
  appKey: string
): string {
  try {
    const url = new URL(apiUrl);
    url.searchParams.set('app_id', appId);
    url.searchParams.set('app_key', appKey);
    return url.href;
  } catch (e) {
    logger.error(e, 'Failed adding API keys to URL');
    return apiUrl;
  }
}
