import pino from 'pino';

let loggerClientSide: pino.Logger;
let loggerServerSide: pino.Logger;

const pinoConfig: pino.LoggerOptions = {
  timestamp: () => `,"@timestamp":"${new Date().toISOString()}"`,
  messageKey: 'message',
  level: process.env.NODE_ENV !== 'production' ? 'trace' : 'info',
  browser: { asObject: false },
  formatters: {
    bindings: ({ pid, hostname }) => ({
      pid,
      hostname,
      tags: process.env.LOG_TAG ? [process.env.LOG_TAG] : [],
      '@version': 1,
      container_id: process.env.HOSTNAME,
    }),
    level: (label) => ({ level: label }),
  },
};

const isClient = () => typeof window !== 'undefined';

const loggerModule = {
  get logger(): pino.Logger {
    if (isClient()) {
      if (!loggerClientSide) {
        loggerClientSide = pino(pinoConfig);
        window.console.debug = window.console.log.bind(console);
      }

      return loggerClientSide;
    } else {
      if (!loggerServerSide) {
        loggerServerSide = pino(pinoConfig);
      }

      return loggerServerSide;
    }
  },
};

export default loggerModule.logger;
