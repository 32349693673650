import Script from 'next/script';
import React from 'react';
import { useAnalyticsAvailability } from 'contexts/AnalyticsAvailabilityContext';
import { yleAnalyticsSdkUrl } from 'services/properties/frontend';
import { initializeAnalytics } from 'services/yleAnalyticsSdk';
import logger from 'services/logger';

export const YleAnalytics: React.FunctionComponent = () => {
  const { setIsAvailable, setHasErrored } = useAnalyticsAvailability();
  return (
    <Script
      src={yleAnalyticsSdkUrl}
      onLoad={() => {
        initializeAnalytics();
        setIsAvailable(true);
        setHasErrored(false);
      }}
      onError={() => {
        logger.warn('Yle Analytics failed to load');
        setIsAvailable(false);
        setHasErrored(true);
      }}
    />
  );
};
