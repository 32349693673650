import { Analytics } from '@yleisradio/areena-types';
import { useEffect } from 'react';
import { useAnalyticsContext } from 'contexts/AnalyticsContext';
import { useAnalyticsAvailability } from 'contexts/AnalyticsAvailabilityContext';
import logger from 'services/logger';
import {
  trackEvent,
  trackOnReceive,
  trackPage,
} from 'services/yleAnalyticsSdk';
import { useReferrer } from '../contexts/ReferrerContext';

export function useAnalyticsForView(analytics: Analytics | undefined): void {
  const { isAvailable: isAnalyticsAvailable } = useAnalyticsAvailability();
  const referrer = useReferrer();

  useEffect(() => {
    if (isAnalyticsAvailable && analytics?.pageload?.comscore) {
      try {
        trackPage({
          ...(referrer && { ns_referrer: referrer }),
          ...analytics.pageload.comscore,
        });
      } catch (e) {
        logger.error(e);
      }
    }
  }, [analytics, isAnalyticsAvailable, referrer]);
}

export function useAnalyticsForTab(analytics: Analytics | undefined): void {
  const { isAvailable: isAnalyticsAvailable } = useAnalyticsAvailability();
  const context = useAnalyticsContext();

  useEffect(() => {
    if (isAnalyticsAvailable && analytics?.pageload?.comscore) {
      try {
        trackPage(analytics.pageload.comscore, context);
      } catch (e) {
        logger.error(e);
      }
    }
  }, [analytics, context, isAnalyticsAvailable]);
}

export function useAnalyticsForControl(analytics: Analytics | undefined): {
  trackAction: () => void;
} {
  const { isAvailable: isAnalyticsAvailable } = useAnalyticsAvailability();
  const context = useAnalyticsContext();

  return {
    trackAction: () => {
      if (isAnalyticsAvailable && analytics?.pageload?.comscore?.countername) {
        try {
          trackEvent(
            analytics.pageload.comscore.countername,
            analytics.pageload.comscore,
            context
          );
        } catch (e) {
          logger.error(e);
        }
      }
    },
  };
}

export function useCustomEventAnalytics(): (eventName: string) => void {
  const { isAvailable: isAnalyticsAvailable } = useAnalyticsAvailability();
  const context = useAnalyticsContext();

  return (eventName: string) => {
    if (isAnalyticsAvailable) {
      try {
        trackEvent(eventName, null, context);
      } catch (e) {
        logger.error(e, `Tracking event with name ${eventName} failed`);
      }
    } else {
      logger.warn('Analytics is not available');
    }
  };
}

export function useOnReceiveAnalytics(): (
  analytics: Analytics | undefined
) => void {
  const { isAvailable: isAnalyticsAvailable } = useAnalyticsAvailability();
  const context = useAnalyticsContext();

  return (analytics: Analytics | undefined) => {
    if (isAnalyticsAvailable) {
      try {
        trackOnReceive(analytics, context);
      } catch (e) {
        logger.error(e, 'Sending onReceive analytics failed');
      }
    } else {
      logger.warn('Analytics is not available');
    }
  };
}
