import React, { useContext, useEffect, useState } from 'react';
import * as LocationsApi from 'services/locations-api';
import logger from 'services/logger';

type ContextValue = LocationsApi.Location | undefined;

// Not exported on purpose, use Provider component and hook below
const LocationContext = React.createContext<ContextValue>(undefined);

export const LocationContextProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [state, setState] = useState<ContextValue>(undefined);

  useEffect(() => {
    LocationsApi.getCurrentLocation()
      .then((location) => {
        setState(location);
      })
      .catch((e) => {
        logger.error(e);
        setState({});
      });
  }, [setState]);

  return (
    <LocationContext.Provider value={state}>
      {children}
    </LocationContext.Provider>
  );
};

export function useLocationContext(): ContextValue {
  return useContext(LocationContext);
}
