import { Control, Notification } from '@yleisradio/areena-types';
import { useReference } from 'hooks/useReference';
import { OperationState } from './type';
import { isReference } from './typeGuards';
import { useAction } from './useAction';

type Props = {
  control: Control | undefined;
  isShareDialogOpen?: boolean;
  setIsShareDialogOpen?: (value: boolean) => void;
  setOperationState?: (newState: OperationState) => void;
};

export function useControl({
  control,
  isShareDialogOpen,
  setIsShareDialogOpen,
  setOperationState,
}: Props): {
  control: Control | null;
  action: (() => void) | undefined;
  executingAction: boolean;
  loading: boolean;
  notifications: Notification[];
} {
  const reference = isReference(control) ? control : null;
  const {
    control: fetchedControl,
    loading,
    mutateControl,
    notifications,
  } = useReference(reference);
  const finalControl = reference ? fetchedControl : control || null;

  const { runAction: action, executing: executingAction = false } =
    useAction({
      control: finalControl,
      mutateControl,
      isShareDialogOpen,
      setIsShareDialogOpen,
      setOperationState,
    }) || {};

  return {
    control: finalControl,
    action,
    executingAction,
    loading,
    notifications,
  };
}
