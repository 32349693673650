import {
  CardPresentation,
  Image as ImageType,
  ListStyle,
} from '@yleisradio/areena-types';
import Image, { ImageLoader } from 'next/image';
import classNames from 'classnames';
import {
  defaultLoader,
  fixedAspectRatioImageLoader,
  loaderUrl,
} from 'utils/cloudinary';
import styles from './CardImage.module.scss';
import React from 'react';

interface CardImageProps {
  image: ImageType | undefined;
  imageStyle: ListStyle['image'] | undefined;
  sizes: string;
  cardPresentation: CardPresentation | undefined;
}

const getPresentationClassName = (
  presentation: CardPresentation | undefined
) => {
  switch (presentation) {
    case 'packageCard':
      return styles.packageCard;
    default:
      return null;
  }
};

function getImageStyleClassName(
  imageStyle: ListStyle['image'] | undefined
): string | undefined {
  switch (imageStyle) {
    case '1:1':
      return styles['aspectRatio_1_1'];
    case '9:13':
      return styles['aspectRatio_9_13'];
    case '16:9':
    default:
      return undefined;
  }
}

function getLoader(imageStyle: ListStyle['image'] | undefined): ImageLoader {
  switch (imageStyle) {
    case '1:1':
    case '9:13':
    case '16:9':
      return fixedAspectRatioImageLoader(imageStyle);
    default:
      return defaultLoader;
  }
}

export const CardImage: React.FunctionComponent<CardImageProps> = ({
  image,
  imageStyle,
  sizes,
  cardPresentation,
}) => {
  if (imageStyle === 'none') return null;

  return (
    <div
      className={classNames(
        styles.container,
        getImageStyleClassName(imageStyle)
      )}
    >
      {image ? (
        <Image
          key={image.id}
          alt={image.alt || ''}
          className={classNames(
            styles.image,
            getPresentationClassName(cardPresentation)
          )}
          fill
          loader={getLoader(imageStyle)}
          role={image.alt ? undefined : 'presentation'}
          sizes={sizes}
          src={loaderUrl(image)}
        />
      ) : null}
    </div>
  );
};
