import { UserInitials } from 'components/UserInitials/UserInitials';
import { useTunnusContext } from 'contexts/TunnusContext';
import { FC } from 'react';
import styles from './Profile.module.scss';

export const Profile: FC = () => {
  const { userInformation } = useTunnusContext();

  if (!userInformation) return null;

  return (
    <div className={styles.root}>
      <div className={styles.initials}>
        <UserInitials size="md" />
      </div>
      <div className={styles.nick}>{userInformation.nick}</div>
      <div className={styles.username}>{userInformation.username}</div>
    </div>
  );
};
