import { Language, Pointer, Service } from '@yleisradio/areena-types';
import { areenaApiProperties } from 'services/properties/frontend';
import { getCommonUiApiParameters } from './fetchers';

const { appBase } = areenaApiProperties;

export function createSearchResultsPointer(
  query: string,
  language: Language,
  service: Service
): Pointer {
  const encodedQuery = encodeURIComponent(query);
  const uri = `${appBase}/v1/ui/search?${getCommonUiApiParameters(
    language
  )}&episodes=true&packages=true&query=${encodedQuery}&service=${service}`;

  return { uri };
}
