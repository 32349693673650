import { AgentConfigOptions, ApmBase, init as apmInit } from '@elastic/apm-rum';
import getConfig from 'next/config';

const createApmRum = (): ApmBase | undefined => {
  const { publicRuntimeConfig } = getConfig();

  if (
    typeof window !== 'undefined' &&
    process.env.NODE_ENV === 'production' &&
    publicRuntimeConfig &&
    publicRuntimeConfig.apmServerUrl
  ) {
    const options: AgentConfigOptions = {
      serviceName: 'areena-web-items',
      active: true,
      serverUrl: publicRuntimeConfig.apmServerUrl,
      environment: publicRuntimeConfig.environment || '',
      serviceVersion: publicRuntimeConfig.versionNumber || '',
    };
    const apmSampleRate = Number(publicRuntimeConfig.apmSampleRate);
    if (apmSampleRate) {
      options.transactionSampleRate = apmSampleRate;
    }
    return apmInit(options);
  }
};

export function init(): void {
  apmRum = createApmRum();
}

export let apmRum: ApmBase | undefined;
