import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Service } from '@yleisradio/areena-types';
import logger from 'services/logger';

type Value = {
  readonly areenaService: Service | undefined;
  readonly setAreenaService: (service: Service | undefined) => void;
};

const AreenaServiceContext = createContext<Value>({
  areenaService: undefined,
  setAreenaService: () => {
    logger.warn(
      "Can't set areenaService because AreenaServiceContext doesn't have a provider"
    );
  },
});

export const AreenaServiceProvider: FC<PropsWithChildren> = ({ children }) => {
  const [service, setService] = useState<Service | undefined>(undefined);

  const value = useMemo<Value>(
    () => ({
      areenaService: service,
      setAreenaService: setService,
    }),
    [service, setService]
  );

  return (
    <AreenaServiceContext.Provider value={value}>
      {children}
    </AreenaServiceContext.Provider>
  );
};

export function useAreenaService(): Value {
  return useContext(AreenaServiceContext);
}
