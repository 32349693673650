import { trackEvents } from 'services/yleAnalyticsSdk';
import { ImpressionEvent } from './types';

class EventStack {
  private events: ImpressionEvent[] = [];

  addEvent(event: ImpressionEvent) {
    this.events.push(event);
  }

  sendEvents() {
    if (this.events.length > 0) {
      trackEvents(this.events);
      this.clear();
    }
  }

  private clear() {
    this.events = [];
  }
}

export const eventStack = new EventStack();
