import logger from 'services/logger';

export function getFirstLineOfText(str: string): string;
export function getFirstLineOfText(str: string | undefined): string | undefined;
export function getFirstLineOfText(
  str: string | undefined
): string | undefined {
  return str && str.split('\n', 1)[0];
}

export function sanitizeHtmlId(str: string): string {
  const id = str.replace(/[^\w.-]/g, '');
  if (!id) {
    logger.warn('Sanitizing HTML id resulted in empty string');
  }
  return id;
}

const hexToInt = (hex: string) => parseInt(hex, 16);

// Areena api gives us #AARRGGBB or #RRGGBB -colors, convert them for web.
export const apiToCssColor = (hexColor: string): string => {
  const [, alpha, red, green, blue] =
    /^#([a-f\d]{2})?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor) ||
    [];

  if (alpha && red && green && blue) {
    const opacity = (hexToInt(alpha) / 255).toFixed(2);
    return `rgba(${hexToInt(red)}, ${hexToInt(green)}, ${hexToInt(
      blue
    )}, ${opacity})`;
  }
  if (red && green && blue) {
    return `rgb(${hexToInt(red)}, ${hexToInt(green)}, ${hexToInt(blue)})`;
  }
  return hexColor;
};

export function numberToPercentage(number: number): string {
  return Math.floor(number * 10000) / 100 + '%';
}
