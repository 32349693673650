import { ListStyle } from '@yleisradio/areena-types';
import { FunctionComponent } from 'react';
import styles from './PlaceholderSearchCard.module.scss';

const getAspectRatioClassName = (listStyle?: ListStyle | undefined) => {
  switch (listStyle?.image) {
    case '1:1':
      return styles.withAspectRatio1_1;
    case '16:9':
    default:
      return styles.withAspectRatio16_9;
  }
};

interface PlaceholderSearchCardProps {
  listStyle: ListStyle | undefined;
}

export const PlaceholderSearchCard: FunctionComponent<
  PlaceholderSearchCardProps
> = ({ listStyle }) => {
  return (
    <div className={styles.searchCardHorizontal}>
      <div className={getAspectRatioClassName(listStyle)}>
        <div className={styles.image}></div>
      </div>
      <span className={styles.title}></span>
    </div>
  );
};
