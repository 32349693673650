import { Analytics } from '@yleisradio/areena-types';
import logger from './logger';
import { yleAnalyticsAccounts } from './properties/frontend';
import { ImpressionEvent } from 'contexts/ImpressionAnalyticsContext/types';

export type Labels = Record<string, string>;

export function initializeAnalytics(): void {
  if (typeof window === 'undefined') {
    throw new Error('Analytics may only be initialized in browser');
  }
  if (!window.yleAnalytics) {
    logger.warn('window.yleAnalytics is not defined');
    return;
  }
  const hostname = window.location.hostname;
  const accounts = yleAnalyticsAccounts(hostname);
  logger.debug(
    `Initializing yle-analytics-sdk with ${accounts.length} accounts (${accounts
      .map((a) => a.type)
      .join(', ')})`
  );
  try {
    window.yleAnalytics.accounts.addAccounts(accounts);
  } catch (e) {
    logger.warn('Initializing analytics failed with error: ', e);
  }
}

export function trackPage(
  comscore: Labels,
  context?: Labels | undefined
): void {
  if (typeof window === 'undefined') {
    throw new Error('trackPage may only be used in browser');
  }
  if (!window.yleAnalytics) {
    logger.warn('window.yleAnalytics is not defined');
    return;
  }

  const { countername: pageName, ...labels } = comscore;
  const labelsWithContext = {
    ...context,
    ...labels,
  };

  if (pageName) {
    logger.debug(`Sending track page analytics with page name ${pageName}`);
    try {
      window.yleAnalytics.trackPage({
        pageName,
        labels: labelsWithContext,
      });
    } catch (e) {
      logger.warn(
        `Tracking page analytics with page name ${pageName} failed with error:`,
        e
      );
    }
  } else {
    logger.debug('Sending track page analytics without page name');
    try {
      window.yleAnalytics.trackPage({ labels: labelsWithContext });
    } catch (e) {
      logger.warn(
        'Tracking page analytics with page without name failed with error:',
        e
      );
    }
  }
}

export function trackEvent(
  eventName: string,
  labels: Labels | null | undefined,
  context: Labels | undefined
): void {
  if (typeof window === 'undefined') {
    throw new Error('trackEvent may only be used in browser');
  }
  if (!window.yleAnalytics) {
    logger.warn('window.yleAnalytics is not defined');
    return;
  }

  const labelsWithContext = { ...context, ...labels };

  logger.debug(`Sending track event analytics with name ${eventName}`);

  try {
    window.yleAnalytics.trackEvent(eventName, {
      labels: labelsWithContext,
    });
  } catch (e) {
    logger.warn(e, `Tracking event with name ${eventName} failed`);
  }
}

export function trackOnReceive(
  analytics: Analytics | undefined,
  context: Labels | undefined
): void {
  if (analytics?.onReceive?.comscore?.countername) {
    trackEvent(
      analytics.onReceive.comscore.countername,
      analytics.onReceive.comscore,
      context
    );
  }
}

export function trackEvents(eventAttributes: Array<ImpressionEvent>): void {
  if (typeof window === 'undefined') {
    throw new Error('trackEvent may only be used in browser');
  }
  if (!window.yleAnalytics) {
    logger.warn('window.yleAnalytics is not defined');
    return;
  }
  try {
    window.yleAnalytics.trackEvents(eventAttributes);
  } catch (e) {
    logger.warn('Tracking event failed with error:', e);
  }
}

export function trackLink(element: HTMLElement, extraLabels?: Labels) {
  if (typeof window === 'undefined') {
    throw new Error('trackLink may only be used in browser');
  }

  if (!window.yleAnalytics) {
    logger.warn('window.yleAnalytics is not defined');
    return;
  }

  try {
    window.yleAnalytics.trackLink(
      element,
      extraLabels && { labels: extraLabels }
    );
  } catch (e) {
    logger.warn(e, 'Tracking link failed');
  }
}
