import { Control as ControlType } from '@yleisradio/areena-types';
import React, { useState } from 'react';
import { useAnalyticsForControl } from 'hooks/analytics';
import { isShareControl } from 'utils/control';
import { sanitizeHtmlId } from 'utils/ui-helpers';
import { ButtonPadding, ButtonSize, ButtonVariant } from 'components/Button';
import { ControlPresentation } from './ControlPresentation';
import { ControlWrapper } from './ControlWrapper/ControlWrapper';
import { Notifications } from 'components/Notifications';
import { Placeholder } from './Placeholder';
import { useControl } from './useControl';
import { LinkIconPosition, LinkTextSize, LinkTheme } from 'components/DSLink';

interface ControlProps {
  control: ControlType;
  controlKey: string;
  onClick?: () => void;
  as: 'button' | 'link';
  padding?: ButtonPadding | undefined;
  size?: ButtonSize | undefined;
  variant?: ButtonVariant | undefined;
  width?: 'full' | undefined;
  textSize?: LinkTextSize | undefined;
  iconPosition?: LinkIconPosition | undefined;
  theme?: LinkTheme | undefined;
}

export const Control: React.FunctionComponent<ControlProps> = ({
  as,
  control: controlProp,
  controlKey,
  onClick,
  padding,
  size,
  variant,
  width,
  textSize,
  iconPosition,
  theme,
}) => {
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const { control, action, executingAction, loading, notifications } =
    useControl({
      control: controlProp,
      isShareDialogOpen,
      setIsShareDialogOpen,
    });
  const { trackAction } = useAnalyticsForControl(control?.analytics);
  const shareDialogId = isShareControl(control)
    ? sanitizeHtmlId(`${controlKey}-dialog`)
    : undefined;

  return (
    <ControlWrapper
      control={control}
      isShareDialogOpen={isShareDialogOpen}
      setIsShareDialogOpen={setIsShareDialogOpen}
      shareDialogId={shareDialogId}
    >
      {control ? (
        <ControlPresentation
          control={control}
          executingAction={executingAction}
          onClick={() => {
            trackAction();
            if (action) action();
            if (onClick) onClick();
          }}
          as={as}
          buttonPadding={padding}
          buttonSize={size}
          buttonVariant={variant}
          iconPosition={iconPosition}
          width={width}
          textSize={textSize}
          theme={theme}
          aria-expanded={
            isShareControl(control) ? isShareDialogOpen : undefined
          }
          aria-controls={shareDialogId}
        />
      ) : null}
      {loading ? <Placeholder size={size} /> : null}
      <Notifications
        notifications={notifications}
        notificationStyle={'transparent'}
      />
    </ControlWrapper>
  );
};
