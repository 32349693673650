import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import logger from 'services/logger';
import { UserInfo, YleTunnusInstance } from 'types/yleTunnus';

/** User information is considered fetched after this amount of milliseconds */
const userInformationFetchWaitTime = 8000;

type Value = {
  isAuthenticated: boolean | null;
  isUserInformationFetched: boolean;
  userInformation: UserInfo | undefined;
  yleTunnusInstance: YleTunnusInstance | undefined;
  setYleTunnusInstance(yleTunnusInstance: YleTunnusInstance | undefined): void;
};

const TunnusContext = createContext<Value>({
  isAuthenticated: null,
  isUserInformationFetched: false,
  userInformation: undefined,
  yleTunnusInstance: undefined,
  setYleTunnusInstance: () => {
    logger.warn(new Error("TunnusContext doesn't have a Provider"));
  },
});

interface TunnusContextProps {
  children: React.ReactNode;
  isAuthenticated: boolean | null;
}

export const TunnusContextProvider: React.FunctionComponent<
  TunnusContextProps
> = ({ children, isAuthenticated }) => {
  const [yleTunnusInstance, setYleTunnusInstance] =
    useState<YleTunnusInstance>();

  const [isUserInformationFetched, setIsUserInformationFetched] =
    useState<boolean>(false);
  const [userInformation, setUserInformation] = useState<UserInfo>();

  useEffect(() => {
    if (yleTunnusInstance) {
      const subscriber = yleTunnusInstance.state.subscribe((state) => {
        setUserInformation(state.user || undefined);
        setIsUserInformationFetched(true);
      });
      return () => {
        subscriber.unsubscribe();
      };
    }
  }, [setIsUserInformationFetched, setUserInformation, yleTunnusInstance]);

  useEffect(() => {
    const id = setTimeout(() => {
      setIsUserInformationFetched(true);
    }, userInformationFetchWaitTime);

    return () => {
      clearTimeout(id);
    };
  }, [setIsUserInformationFetched]);

  const value = useMemo<Value>(
    () => ({
      isAuthenticated: isAuthenticated,
      isUserInformationFetched,
      userInformation,
      yleTunnusInstance,
      setYleTunnusInstance,
    }),
    [
      isAuthenticated,
      isUserInformationFetched,
      userInformation,
      yleTunnusInstance,
      setYleTunnusInstance,
    ]
  );

  return (
    <TunnusContext.Provider value={value}>{children}</TunnusContext.Provider>
  );
};

export function useTunnusContext(): Value {
  return useContext(TunnusContext);
}
