import {
  ObservableLabels,
  Observe,
} from 'contexts/ImpressionAnalyticsContext/types';
import { useClickCapture } from 'contexts/ImpressionAnalyticsContext/useClickCapture';
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import { useShowObserver } from './useShowObserver';

type ImpressionAnalyticsContextType = {
  observe: Observe;
  captureClick: (labels: ObservableLabels) => void;
};

type Props = {
  children: ReactNode;
  uiElementPosition: number;
  uiElementType: string;
};

const ImpressionAnalyticsContext =
  createContext<ImpressionAnalyticsContextType>({
    observe(): never {
      throw new Error('No providers for ImpressionAnalyticsContext');
    },
    captureClick(): never {
      throw new Error('No providers for ImpressionAnalyticsContext');
    },
  });

export const ImpressionAnalyticsContextProvider: FunctionComponent<Props> = ({
  children,
  uiElementPosition,
  uiElementType,
}) => {
  const observe = useShowObserver({ uiElementPosition, uiElementType });
  const captureClick = useClickCapture({ uiElementPosition, uiElementType });

  const contextValue = useMemo<ImpressionAnalyticsContextType>(
    () => ({ observe, captureClick }),
    [captureClick, observe]
  );

  return (
    <ImpressionAnalyticsContext.Provider value={contextValue}>
      {children}
    </ImpressionAnalyticsContext.Provider>
  );
};

export function useImpressionAnalyticsContext(): ImpressionAnalyticsContextType {
  return useContext(ImpressionAnalyticsContext);
}
