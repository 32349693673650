import { BaseResponse } from '@yleisradio/areena-types';
import { ResponseHeaders } from './types';

function isObject(something: unknown): something is object {
  return typeof something === 'object' && something !== null;
}

export function isAreenaApiResponse<T extends BaseResponse>(
  something: unknown
): something is T {
  return (
    isObject(something) &&
    (!('meta' in something) || isObject(something.meta)) &&
    (!('notifications' in something) || Array.isArray(something.notifications))
  );
}

export function isResponseHeaders(
  something: unknown
): something is ResponseHeaders {
  return (
    isObject(something) &&
    (!('cache-control' in something) ||
      typeof something['cache-control'] === 'string') &&
    (!('set-cookie' in something) ||
      (Array.isArray(something['set-cookie']) &&
        (!something['set-cookie'][0] ||
          typeof something['set-cookie'][0] === 'string')))
  );
}
