import { Action } from 'components/Controls/Control/actions/type';
import { useTunnusContext } from 'contexts/TunnusContext';
import { useCallback } from 'react';
import logger from 'services/logger';

export function useLogOutAction(): Action {
  const { yleTunnusInstance } = useTunnusContext();

  const runAction = useCallback(async () => {
    try {
      if (yleTunnusInstance) {
        await yleTunnusInstance.logOut();
      } else {
        throw new Error(
          "Couldn't initiate logout as yleTunnusInstance is undefined"
        );
      }
    } catch (e) {
      logger.error(e, 'Error when initiating logout');
    }
  }, [yleTunnusInstance]);

  return {
    runAction,
    executing: false,
  };
}
