import { isbot } from 'isbot';
import { useTranslation } from 'hooks/useTranslation';
import styles from './BrowserSupportAlert.module.scss';

export const BrowserFeatureDetect: React.FC = () => {
  const t = useTranslation();
  const isServersideRendered = typeof window === 'undefined';

  if (isServersideRendered) {
    return null;
  } else {
    const isStaticImportSupported = () => {
      return 'noModule' in HTMLScriptElement.prototype;
    };
    const isDynamicImportSupported = () => {
      try {
        eval("try { import('foo').catch(() => {}); } catch (e) { }");
        return true;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        return false;
      }
    };

    const isBrowserSupported =
      window.navigator.webdriver ||
      isbot(window.navigator.userAgent) ||
      (isStaticImportSupported() && isDynamicImportSupported());

    if (!isBrowserSupported) {
      return (
        <div className={styles.supportAlert}>
          {t('deprecatedInstructions')} &nbsp;
          <a href={t('deprecatedInstructionUrl')}>{t('deprecatedLinkText')}</a>
        </div>
      );
    }

    return null;
  }
};
