import { useTranslation, Key } from 'hooks/useTranslation';

const getHoursString = (date: Date) => {
  const hours = date.getUTCHours();
  return hours > 0 ? `${hours.toString()}:` : '';
};

const getMinutesString = (date: Date) =>
  `${date.getUTCMinutes().toString().padStart(2, '0')}:`;

const getSecondsString = (date: Date) =>
  date.getUTCSeconds().toString().padStart(2, '0');

export const progressToString = (progressInSeconds: number) => {
  const date = new Date(progressInSeconds * 1000);
  const hours = getHoursString(date);
  const minutes = getMinutesString(date);
  const seconds = getSecondsString(date);
  return `${hours}${minutes}${seconds}`;
};

export type TimeType = 'hour' | 'minute' | 'second';
export type NounType = 'singular' | 'plural';

export const getTimeText = (
  translate: (key: Key) => string,
  key: TimeType,
  value: number
) => {
  switch (value) {
    case 0:
      return null;
    case 1:
      return translate(key);
    default:
      return `${value} ${translate(`${key}s`)}`;
  }
};

const getHoursText = (translate: (key: Key) => string, value: number) =>
  getTimeText(translate, 'hour', value);

const getMinutesText = (translate: (key: Key) => string, value: number) =>
  getTimeText(translate, 'minute', value);

const getSecondsText = (translate: (key: Key) => string, value: number) =>
  getTimeText(translate, 'second', value);

const joinWithAnd = (timeParts: Array<string | null>, and: string): string => {
  return timeParts.filter((part) => part !== null).join(` ${and} `);
};

export const useGetProgressToText = (progressInSeconds = 0): string => {
  const translate = useTranslation();
  const date = new Date(progressInSeconds * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  const hoursText = getHoursText(translate, hours);
  const minutesText = getMinutesText(translate, minutes);
  const secondsText = getSecondsText(translate, seconds);

  return joinWithAnd([hoursText, minutesText, secondsText], translate('and'));
};

/**
 * Returns parameter time in HH:mm format.
 * @param date
 * @returns {`${string}:${string}`}
 */
export const getFormattedTime = (date: Date) =>
  `${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
