import { ShareWrapper } from './ShareWrapper';
import { WrapperComponent } from './type';

export const ControlWrapper: WrapperComponent = (props) => {
  const { children, control } = props;
  switch (control?.functionality?.id) {
    case 'share':
      return <ShareWrapper {...props} />;
    default:
      return <>{children}</>;
  }
};
