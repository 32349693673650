import { Item, Service } from '@yleisradio/areena-types';

export function isAreenaProgramID(id: string): boolean {
  return /^1-\d+$/.test(id);
}

export function isAreenaProgramItem(item: Item): boolean {
  return isAreenaProgramID(item.id);
}

export function isRadioItem(item: Item): boolean {
  return item.type.startsWith('Radio') || item.mediaType === 'audio';
}

export function isTVItem(item: Item): boolean {
  return item.type.startsWith('TV') || item.mediaType === 'video';
}

export function getItemService(item: Item): Service {
  return isRadioItem(item) ? 'radio' : 'tv';
}
