import { useCallback, useEffect, useState } from 'react';
import logger from 'services/logger';
import { QueueItem } from './QueueItem';

const LOCAL_STORAGE_QUEUE_KEY = 'podcasts:playQueue';

export function useQueueState(): [
  queue: QueueItem[],
  setQueue: (queue: QueueItem[]) => void,
] {
  const [queue, setQueueState] = useState<QueueItem[]>([]);

  useEffect(function loadInitialQueue() {
    setQueueState(
      getFromLocalStorage().map((programId, index) => ({
        key: index.toString(),
        programId,
      }))
    );
  }, []);

  const setQueue = useCallback((newQueue: QueueItem[]) => {
    setQueueState(newQueue);
    saveToLocalStorage(newQueue);
  }, []);

  return [queue, setQueue];
}

function getFromLocalStorage(): string[] {
  const item = window.localStorage.getItem(LOCAL_STORAGE_QUEUE_KEY);

  if (item) {
    try {
      const candidate: unknown = JSON.parse(item);

      if (
        Array.isArray(candidate) &&
        candidate.every((item) => typeof item == 'string')
      ) {
        return candidate;
      } else {
        logger.error('Local storage value is not an array of strings');
      }
    } catch (e) {
      logger.error(e, 'Error parsing local storage value');
    }
  }

  return [];
}

function saveToLocalStorage(queue: QueueItem[]): void {
  window.localStorage.setItem(
    LOCAL_STORAGE_QUEUE_KEY,
    JSON.stringify(queue.map((item) => item.programId))
  );
}
