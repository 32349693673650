import { Image } from '@yleisradio/areena-types';
import { ImageLoader, ImageLoaderProps } from 'next/image';

const root = 'https://images.cdn.yle.fi/image/upload/';

export function loaderUrl(image: Image): string {
  return `/v${image.version}/${image.id}`;
}

export function iconLoaderUrl(image: Image): string {
  return `/v${image.version}/areena/apps/${image.id}`;
}

export function contentRatingImgUrl(imageId: string): string {
  return `/${imageId}.png`;
}

export const defaultLoader: ImageLoader = ({ src, width, quality }) => {
  const params = `f_auto,c_limit,w_${width},q_${quality || 'auto'}`;
  return `${root}${params}${src}`;
};

export function fixedAspectRatioImageLoader(
  aspectRatio: '16:9' | '1:1' | '9:13'
): ImageLoader {
  return ({ src, width, quality }) => {
    const params = `f_auto,c_fill,ar_${aspectRatio},w_${width},q_${
      quality || 'auto'
    }`;
    return `${root}${params}${src}`;
  };
}

export const squareImageLoader = fixedAspectRatioImageLoader('1:1');

export const iconImageLoader: ImageLoader = ({
  src,
  width,
}: ImageLoaderProps): string => {
  const params = [
    'f_auto',
    'q_auto',
    'c_pad',
    'g_south_west',
    'h_48',
    `dpr_1`,
    `w_${width}`,
  ].join(',');
  return `${root}${params}${src}`;
};

export const logoImageLoader: ImageLoader = ({
  src,
  width,
  quality,
}: ImageLoaderProps): string => {
  const params = [
    'f_auto',
    'c_pad',
    'g_south_west',
    'ar_270:148',
    `w_${width}`,
    `q_${quality || 'auto'}`,
  ].join(',');
  return `${root}${params}${src}`;
};

export const overlayLogoImageLoader: ImageLoader = ({
  src,
  width,
  quality,
}: ImageLoaderProps): string => {
  const params = [
    'f_auto',
    'c_pad',
    'g_south_west',
    `w_${width}`,
    `q_${quality || 'auto'}`,
  ].join(',');
  return `${root}${params}${src}`;
};

export const guideChannelImageLoader: ImageLoader = ({
  src,
  width,
  quality,
}: ImageLoaderProps): string => {
  const params = [
    'f_auto',
    'c_pad',
    'g_west',
    'ar_150:32',
    `w_${width}`,
    `q_${quality || 'auto'}`,
  ].join(',');
  return `${root}${params}${src}`;
};

export function backgroundImageLoader({
  aspectRatio,
  isBlurred,
}: {
  aspectRatio: '16:9' | '1:1';
  isBlurred: boolean;
}): ImageLoader {
  return ({ src, width, quality }: ImageLoaderProps): string => {
    const params = [
      'f_auto',
      'c_fill',
      `ar_${aspectRatio}`,
      'g_north',
      `w_${width}`,
      `q_${quality || 'auto'}`,
      ...(isBlurred ? ['e_blur:1800'] : []),
    ].join(',');
    return `${root}${params}${src}`;
  };
}

export function channelPageLogoImageUrl({
  image,
  width,
  height,
  dpr,
}: {
  image: Image;
  width: number;
  height: number;
  dpr: number;
}): string {
  const params = [
    'f_auto',
    'q_auto',
    'c_pad',
    'g_west',
    `w_${width}`,
    `h_${height}`,
    `dpr_${dpr}`,
  ].join(',');

  return `${root}${params}/v${image.version}/${image.id}.png`;
}

export function menuImageUrl(image: Image): string {
  return `${root}c_fill,dpr_1.0,f_auto,q_auto:eco/v${image.version}/${image.id}.png`;
}

export function openGraphImageUrl(
  image: Image,
  ratio: '16:9' | '1:1' | undefined = '16:9'
): string {
  return `${root}f_jpg,${
    ratio === '16:9' ? 'c_fill' : 'c_pad,b_auto:predominant_gradient'
  },w_1920,h_1080,q_auto/v${image.version}/${image.id}.jpg`;
}
