import { Control as ControlType } from '@yleisradio/areena-types';
import classNames from 'classnames';
import { getControlKey } from 'utils/control';
import { ButtonPadding, ButtonSize, ButtonVariant } from 'components/Button';
import React from 'react';
import { Control } from './Control';
import styles from './Controls.module.scss';
import { ControlPresentationType } from './Control/ControlPresentation';
import { LinkIconPosition, LinkTextSize, LinkTheme } from 'components/DSLink';

interface ControlsProps {
  as: ControlPresentationType;
  controls: ControlType[] | undefined;
  direction?: 'horizontal' | 'vertical';
  padding?: ButtonPadding | undefined;
  size?: ButtonSize | undefined;
  variation?: ButtonVariant | undefined;
  textSize?: LinkTextSize | undefined;
  iconPosition?: LinkIconPosition | undefined;
  theme?: LinkTheme | undefined;
}

export const Controls: React.FunctionComponent<ControlsProps> = ({
  as,
  controls = [],
  direction,
  padding,
  size,
  variation,
  textSize,
  iconPosition,
  theme,
}) => {
  if (!controls.length) return null;

  return (
    <div
      className={classNames(
        styles.group,
        direction === 'vertical' && styles.groupVertical
      )}
    >
      {controls.map((c) => {
        const key = getControlKey(c);
        return (
          <Control
            as={as}
            controlKey={key}
            control={c}
            key={key}
            padding={padding}
            size={size}
            variant={variation}
            textSize={textSize}
            iconPosition={iconPosition}
            theme={theme}
          />
        );
      })}
    </div>
  );
};
