import { BaseResponse } from '@yleisradio/areena-types';
import { isAreenaApiResponse } from './typeGuards';

class AreenaApiAssertionError extends Error {
  constructor(expectedType: string, actual: unknown) {
    super(`Failed to assert that received ${typeof actual} is ${expectedType}`);
    this.name = 'AreenaApiAssertionError';
  }
}

export function assertIsAreenaApiResponse<T extends BaseResponse>(
  data: unknown
): asserts data is T {
  if (!isAreenaApiResponse(data)) {
    throw new AreenaApiAssertionError('AreenaApiResponse', data);
  }
}
