import { Service } from '@yleisradio/areena-types';

export type NavigationService = Service | 'broadcasts' | 'search';

const broadcastPaths = [
  '/tv/opas',
  '/tv/guide',
  '/podcastit/opas',
  '/poddar/guide',
  '/suorat',
  '/direkt',
];

export const navigationServiceFromClientPath = (
  path: string
): NavigationService | undefined => {
  if (broadcastPaths.some((broadcastPath) => path.startsWith(broadcastPath)))
    return 'broadcasts';

  if (path.startsWith('/tv')) return 'tv';

  if (path.startsWith('/podcastit') || path.startsWith('/poddar'))
    return 'radio';

  if (path.startsWith('/hae') || path.startsWith('/sok')) return 'search';
  return undefined;
};
