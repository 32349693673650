import classNames from 'classnames';
import type { ButtonSize } from 'components/Button';
import React from 'react';
import styles from './Placeholder.module.scss';

type Props = {
  size: ButtonSize | undefined;
};

export const Placeholder: React.FunctionComponent<Props> = ({
  size = 'md',
}) => {
  return <span className={classNames(styles.placeholder, styles[size])} />;
};
