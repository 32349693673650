import { Language } from '@yleisradio/areena-types';
import { isbot } from 'isbot';
import { Consent } from 'types/yleConsentSdk';
import { isTestEnvironment } from 'utils/environment';

export const showConsentModal = () => {
  if (!window.yleConsentSdk)
    throw new Error('window.yleConsentSdk not available');

  window.yleConsentSdk.show();
};

type InitProps = {
  language: Language;
  onConsentChange: (consent: Consent) => void;
};

export const init = ({ language, onConsentChange }: InitProps): void => {
  if (typeof window === 'undefined')
    throw new Error('yle-consent-sdk can only be run on client');

  if (!window.yleConsentSdk) throw new ConsentSdkNotAvailableError();

  const environment = isTestEnvironment() ? 'test' : 'prod';

  if (!window.navigator.webdriver && !isbot(window.navigator.userAgent)) {
    window.yleConsentSdk.onChange(onConsentChange);

    window.yleConsentSdk.init({
      language: language === 'sv' ? 'SV' : 'FI',
      environment,
    });
  }
};

export class ConsentSdkNotAvailableError extends Error {
  constructor() {
    super('window.yleConsentSdk not available');
    this.name = this.constructor.name;
  }
}
