import { useLocalStorage } from 'hooks/useLocalStorage';
import logger from 'services/logger';

export function useShowRecommendationsQueue(): [
  boolean,
  (value: boolean) => void,
] {
  const [state, setState] = useLocalStorage<boolean>(
    'playQueueShowRecommendations',
    true
  );

  if (typeof state !== 'boolean') {
    logger.warn(
      `Incompatible local storage value (${typeof state}) for show recommendation queue`
    );
  }
  return [!!state, setState];
}
