import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import logger from 'services/logger';

type Value = {
  readonly addEventHandler: (eventHandler: () => void, tag: string) => void;
  readonly removeEventHandler: (tag: string) => void;
};

const EscHandlerContext = createContext<Value>({
  addEventHandler: () => {
    logger.warn(
      "Can't set addEventHandler because EscHandlerContext doesn't have a provider"
    );
  },
  removeEventHandler: () => {
    logger.warn(
      "Can't set removeEventHandler because EscHandlerContext doesn't have a provider"
    );
  },
});

type EventHandler = {
  tag: string;
  callBack: () => void;
};
export const EscHandlerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [handlers, setHandlers] = useState<EventHandler[]>([]);

  useEffect(() => {
    function listener(event: KeyboardEvent) {
      if (event.key === 'Esc' || event.key === 'Escape') {
        const handler = handlers.at(-1);
        if (!handler) return;

        handler.callBack();
        removeEventHandler(handler.tag);
      }
    }
    if (handlers.length > 0) {
      window.document.addEventListener('keydown', listener);
      return () => window.document.removeEventListener('keydown', listener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlers, setHandlers]);

  const addEventHandler = useCallback(
    (eventHandler: () => void, tag: string) => {
      setHandlers((prevState) => [
        ...prevState,
        { tag, callBack: eventHandler },
      ]);
    },
    [setHandlers]
  );

  const removeEventHandler = useCallback(
    (tag: string) => {
      setHandlers((prevState) =>
        prevState.filter((handler) => handler.tag !== tag)
      );
    },
    [setHandlers]
  );

  const value = useMemo<Value>(
    () => ({
      addEventHandler,
      removeEventHandler,
    }),
    [addEventHandler, removeEventHandler]
  );

  return (
    <EscHandlerContext.Provider value={value}>
      {children}
    </EscHandlerContext.Provider>
  );
};

export function useEscHandler(): Value {
  return useContext(EscHandlerContext);
}
