import { BaseResponse } from '@yleisradio/areena-types';
import * as Duration from 'iso8601-duration';
import { useEffect, useRef } from 'react';
import logger from 'services/logger';

/*
setTimeout executes timeout immediately if delay is longer than 2,147,483,647 ms
That's why there has to be a reasonable maximum value.
See: https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value
*/
const MAX_DELAY = 86_400_000; // 1 day

type Options = {
  data: BaseResponse | null | undefined;
  mutate: () => void;
};

/**
 * Refresh given Areena API resource after the time specified in current API response has passed.
 * Run the function returned in useSWR's onSuccess hook.
 */
export function useRefresher(): (options: Options) => void {
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout>>();

  // Cancel refresh when dismounting
  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
    };
  }, [timeoutIdRef]);

  return ({ data, mutate }) => {
    if (!data) return;

    const now = new Date();
    const delay = getRefreshDelay(data, now);

    if (delay > 0 && delay <= MAX_DELAY) {
      logger.debug(`Refetching resource after ${delay} milliseconds`);
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = setTimeout(() => {
        mutate();
      }, delay);
    }
  };
}

/**
 * Get milliseconds after which the API recommends to refetch the resource
 */
function getRefreshDelay(response: BaseResponse, now: Date): number {
  if (!response || !response.meta) {
    return NaN;
  }

  const { meta } = response;

  if (meta.refreshAt) {
    const refreshAt = new Date(meta.refreshAt);
    if (refreshAt > now) {
      return refreshAt.getTime() - now.getTime();
    }
  }
  if (meta.refreshIn) {
    const refreshInMs =
      Duration.toSeconds(Duration.parse(meta.refreshIn), now) * 1000;
    if (refreshInMs > 0) {
      return refreshInMs;
    }
  }
  return NaN;
}
