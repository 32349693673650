import React, { FC, useEffect, useState } from 'react';
import styles from './MegaMenu.module.scss';
import { GridContainer, GridElement } from 'components/Grid';
import { ServiceSwitch } from 'components/ServiceSwitch';
import { useAreenaService } from 'contexts/AreenaServiceContext';
import { Language, List, Service } from '@yleisradio/areena-types';
import {
  BrowseMenuContent,
  BrowseMenuContentBlock,
} from '@yleisradio/areena-types/domain/v2/browseMenuContent';
import { CategoryLinkControl } from 'components/CategoryLinkControl/CategoryLinkControl';
import { getControlKey } from 'utils/control';
import { TextLinkControl } from 'components/TextLinkControl/TextLinkControl';
import { ImageLinkControl } from 'components/ImageLinkControl/ImageLinkControl';
import { useUILanguage } from 'hooks/useUILanguage';
import { createSearchResultsPointer } from 'services/areena-api/pointer';
import { MegaMenuSearchList } from './MegaMenuSearchList/MegaMenuSearchList';
import { useCustomEventAnalytics } from 'hooks/analytics';
import { useTranslation } from 'hooks/useTranslation';

export const MENU_ID = 'mega-menu';

type Props = {
  searchQuery: string | null;
  closeMenu(): void;
  clearSearchQuery(): void;
  browseMenuContent: BrowseMenuContent | null;
};

const findBlock =
  (blockType: BrowseMenuContentBlock['type']) =>
  (block: BrowseMenuContentBlock) =>
    block.type === blockType;

export const MegaMenu: FC<Props> = ({
  searchQuery,
  closeMenu,
  clearSearchQuery,
  browseMenuContent,
}) => {
  const { areenaService } = useAreenaService();
  const [activeService, setActiveService] = useState<Service>(
    areenaService ?? 'tv'
  );
  const language = useUILanguage();
  const t = useTranslation();
  const trackEvent = useCustomEventAnalytics();

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);

  useEffect(() => {
    if (!searchQuery) {
      setDebouncedSearchQuery(searchQuery);
      return;
    }

    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchQuery]);

  if (!browseMenuContent) return null;

  const isPodcastService = activeService === 'radio';

  const activeBlocks = isPodcastService
    ? browseMenuContent['podcastBlocks']
    : browseMenuContent['tvBlocks'];
  const categoryImageLinkBlock = activeBlocks.find(
    findBlock('categoryImageLinks')
  );
  const categoryLinkBlock = activeBlocks.find(findBlock('categoryLinks'));
  const textLinkBlock = activeBlocks.find(findBlock('textLinks'));

  const imageLinkSizes = isPodcastService
    ? '(min-width: 1400px) 12rem, (min-width: 1020px) 10rem, (min-width: 640px) 8rem, (min-width: 480px) 30vw, 45vw'
    : '(min-width: 1400px) 16rem, (min-width: 1020px) 14rem, (min-width: 640px) 12rem, (min-width: 480px) 30vw, 45vw';

  return (
    <div
      role="dialog"
      aria-label={t('searchAndBrowse')}
      className={styles.container}
      id={MENU_ID}
    >
      <div className={styles.innerContainer}>
        <GridContainer>
          <GridElement mobile={12}>
            <ServiceSwitch
              activeService={activeService}
              setActiveService={setActiveService}
              onClick={() => trackEvent('header-service-switch-click')}
            />
          </GridElement>
        </GridContainer>

        {!debouncedSearchQuery ? (
          <GridContainer>
            {categoryImageLinkBlock &&
              categoryImageLinkBlock.controls?.map((control) => (
                <GridElement
                  key={getControlKey(control)}
                  mobile={6}
                  mobileLandscape={4}
                  tablet={isPodcastService ? 3 : 4}
                  desktopSmall={isPodcastService ? 6 : 8}
                >
                  <ImageLinkControl
                    control={control}
                    onClick={closeMenu}
                    service={activeService}
                    sizes={imageLinkSizes}
                  />
                </GridElement>
              ))}
          </GridContainer>
        ) : (
          <MegaMenuSearchList
            lists={{
              tv: createSearchResultsList(debouncedSearchQuery, language, 'tv'),
              radio: createSearchResultsList(
                debouncedSearchQuery,
                language,
                'radio'
              ),
            }}
            searchQuery={debouncedSearchQuery}
            clearSearchQuery={clearSearchQuery}
            service={activeService}
            closeMenu={closeMenu}
          />
        )}

        {categoryLinkBlock &&
          categoryLinkBlock.controls &&
          categoryLinkBlock.controls.length > 0 &&
          !debouncedSearchQuery && (
            <div
              className={styles.categoryLinksContainer}
              role="group"
              aria-label={t('categories')}
            >
              {categoryLinkBlock.controls.map((control) => (
                <CategoryLinkControl
                  key={getControlKey(control)}
                  control={control}
                  onClick={closeMenu}
                  service={activeService}
                />
              ))}
            </div>
          )}

        {textLinkBlock &&
          textLinkBlock.controls &&
          textLinkBlock.controls.length > 0 &&
          !debouncedSearchQuery && (
            <div className={styles.textLinkContainer}>
              {textLinkBlock.controls.map((control) => (
                <TextLinkControl
                  key={getControlKey(control)}
                  control={control}
                  onClick={closeMenu}
                  service={activeService}
                />
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

function createSearchResultsList(
  query: string,
  language: Language,
  service: Service
): List {
  return {
    controls: [],
    filters: [],
    presentation: 'medium-grid',
    source: createSearchResultsPointer(query, language, service),
    style: {
      image: service === 'radio' ? '1:1' : '16:9',
      layout: 'horizontal',
      size: 'small',
    },
    type: 'list',
  };
}
