import React, { useEffect, useMemo, useRef } from 'react';
import { ObservableLabels } from './types';
import { useAnalyticsContext } from 'contexts/AnalyticsContext';
import { useImpressionAnalyticsContext } from '.';

type Props = {
  children: React.ReactNode;
  itemPosition: number;
  yleId: string | undefined;
  visible?: boolean;
};

export const Observable = ({
  children,
  itemPosition,
  yleId,
  visible = true,
}: Props) => {
  const analyticsContext = useAnalyticsContext();
  const { captureClick, observe } = useImpressionAnalyticsContext();

  const ref = useRef<HTMLDivElement | null>(null);

  const impressionData: ObservableLabels = useMemo(
    () => ({
      yle_id: yleId,
      item_position: itemPosition,
      ...analyticsContext,
    }),
    [yleId, itemPosition, analyticsContext]
  );

  useEffect(() => {
    if (visible && ref.current) {
      const unobserve = observe(ref.current, impressionData);
      return () => unobserve();
    }
  }, [impressionData, observe, visible]);

  return (
    <div onClickCapture={() => captureClick(impressionData)} ref={ref}>
      {children}
    </div>
  );
};
