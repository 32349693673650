import { toast } from './NotificationToaster';
import { getTranslation } from 'services/translations';
import { Language } from '@yleisradio/areena-types';

export function toastPlaybackError(language: Language) {
  void toast(
    getTranslation(language, 'playbackStartError'),
    'error',
    getTranslation(language, 'notificationHelpTextGeneric')
  );
}
