import { getTranslation, Key, TranslationArgs } from 'services/translations';
import { useUILanguage } from './useUILanguage';

export type { Key };

export function useTranslation(): <K extends Key>(
  key: K,
  ...translationArgs: TranslationArgs<K>
) => string {
  const language = useUILanguage();

  return function t(key, ...translationArgs) {
    return getTranslation(language, key, ...translationArgs);
  };
}
