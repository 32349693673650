import { Label as LabelType } from '@yleisradio/areena-types';
import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import { apiToCssColor } from 'utils/ui-helpers';
import { Label } from 'components/Label';
import styles from './CardLabels.module.scss';

function getStyleForLabel(label: LabelType): CSSProperties {
  return {
    backgroundColor:
      label.backgroundColor && apiToCssColor(label.backgroundColor),
    color: label.color && apiToCssColor(label.color),
  };
}

interface LabelProps {
  labels: LabelType[];
  isHighlight?: boolean;
}

export const CardLabels: React.FunctionComponent<LabelProps> = ({
  labels,
  isHighlight,
}) => {
  if (!labels.length) return null;

  const className = classNames(
    isHighlight ? styles.highlightLabel : styles.genericLabel
  );

  return (
    <div className={styles.labelList}>
      {labels.map((label, index) => (
        <span key={index} className={className} style={getStyleForLabel(label)}>
          <Label label={label} />
        </span>
      ))}
    </div>
  );
};
