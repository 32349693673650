import { yleTunnusSdkUrl } from 'services/properties/frontend';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';
import { initializeTunnus } from 'services/yleTunnusSdk';
import { useUILanguage } from 'hooks/useUILanguage';
import logger from 'services/logger';
import { useTunnusContext } from 'contexts/TunnusContext';
import { useAnalyticsAvailability } from 'contexts/AnalyticsAvailabilityContext';

export const YleTunnus = (): JSX.Element => {
  const language = useUILanguage();

  const { isAuthenticated, setYleTunnusInstance, yleTunnusInstance } =
    useTunnusContext();
  const { isAvailable: isAnalyticsAvailable, hasErrored: hasAnalyticsErrored } =
    useAnalyticsAvailability();
  const router = useRouter();

  useEffect(() => {
    if (yleTunnusInstance) {
      const subscriber = yleTunnusInstance.state.subscribe((state) => {
        if (isAuthenticated !== null && state.loggedIn !== isAuthenticated) {
          // Do client-side refresh
          router.replace(router.asPath, undefined, { scroll: false });
        }
      });
      return () => {
        subscriber.unsubscribe();
      };
    }
  }, [isAuthenticated, router, yleTunnusInstance]);

  return (
    <>
      {(isAnalyticsAvailable || hasAnalyticsErrored) && (
        <Script
          src={yleTunnusSdkUrl}
          onLoad={() => {
            initializeTunnus(language)
              .then((yleTunnusInstance) => {
                setYleTunnusInstance(yleTunnusInstance);
              })
              .catch((e) => {
                logger.error(e, 'Error during YleTunnus initialization');
              });
          }}
        />
      )}
    </>
  );
};
