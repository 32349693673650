import { apmRum } from 'services/apm-rum';
import logger from 'services/logger';
import { SWRConfiguration } from 'swr';

/**
 * Returns fetch function that aborts the request after specified timeout
 * @param timeout milliseconds to wait before aborting the fetch
 * @returns fetch function with abort signal
 */
export function fetchWithTimeout(timeout: number): typeof fetch {
  if (typeof AbortController != 'undefined') {
    return async (input, init) => {
      const controller = new AbortController();

      const timeoutId = setTimeout(() => {
        controller.abort();
      }, timeout);

      const response = await fetch(input, {
        ...init,
        signal: controller.signal,
      });

      clearTimeout(timeoutId);

      return response;
    };
  } else {
    return fetch;
  }
}

export const globalSWRConfig = {
  onError(err: unknown) {
    logger.error(err);
    if (typeof err === 'string' || err instanceof Error)
      apmRum?.captureError(err);
  },
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
} as const satisfies SWRConfiguration;
