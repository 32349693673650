import { Control } from '@yleisradio/areena-types';
import React from 'react';
import PlaylistPlusIcon from 'assets/playlistPlusIcon.svg';
import PlaylistCheckIcon from 'assets/playlistCheckIcon.svg';
import { Button, ButtonPadding, ButtonSize, ButtonVariant } from './Button';
import { useTranslation } from 'hooks/useTranslation';
import { usePlayerState } from 'contexts/PlayerStateContext';
import styles from './AddToQueueButton.module.scss';
import { useTunnusContext } from 'contexts/TunnusContext';

interface Props {
  control: Control | undefined;
  onClick: React.MouseEventHandler | undefined;
  padding: ButtonPadding | undefined;
  size: ButtonSize | undefined;
  variant: ButtonVariant | undefined;
}

export const AddToQueueButton: React.FunctionComponent<Props> = ({
  control,
  onClick,
  padding,
  size,
  variant = 'secondary',
}) => {
  const t = useTranslation();
  const { isPointerTargetInQueue } = usePlayerState();
  const { isAuthenticated } = useTunnusContext();

  const getButtonText = () => {
    if (isAuthenticated) {
      return isPointerTargetInQueue(control?.destination)
        ? t('removeFromQueue')
        : t('addToQueue');
    }
    return t('addToQueue');
  };

  const getIcon = () => {
    if (isAuthenticated) {
      return isPointerTargetInQueue(control?.destination)
        ? PlaylistCheckIcon
        : PlaylistPlusIcon;
    }
    return PlaylistPlusIcon;
  };

  if (!control) return null;
  return (
    <>
      <span className={styles.mobile}>
        <Button
          text={getButtonText()}
          onClick={onClick}
          padding={padding}
          size={size}
          variant={variant}
          iconLeft={getIcon()}
          iconOnly
        />
      </span>
      <span className={styles.desktop}>
        <Button
          text={getButtonText()}
          onClick={onClick}
          padding={padding}
          size={size}
          variant={variant}
          iconLeft={getIcon()}
        />
      </span>
    </>
  );
};
