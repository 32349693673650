import { Control } from '@yleisradio/areena-types';
import { Action } from './type';
import { usePlayerState } from 'contexts/PlayerStateContext';
import { useState } from 'react';
import { useUILanguage } from 'hooks/useUILanguage';
import { idFromPointer } from 'utils/pointer';
import logger from 'services/logger';
import { getAvailablePlayerByProgramId } from 'services/areena-api/fetchers';
import { toastPlaybackError } from 'components/Notifications';

export function useServiceAction(control: Control | null): Action {
  const { setActivePlayer } = usePlayerState();
  const [isExecuting, setIsExecuting] = useState(false);
  const language = useUILanguage();

  const serviceId = idFromPointer(control?.destination);

  async function serviceAction() {
    if (isExecuting) return;

    if (!serviceId) {
      logger.error(new Error('Service id needs to be defined'));
      toastPlaybackError(language);
      return;
    }

    setIsExecuting(true);

    getAvailablePlayerByProgramId(serviceId, language)
      .then((player) => {
        if (!player)
          throw new Error('No available service for player, closing player');
        setActivePlayer(player);
      })
      .catch((e) => {
        logger.error(e);
        setActivePlayer(undefined);
        toastPlaybackError(language);
      })
      .finally(() => setIsExecuting(false));
  }

  return {
    runAction: serviceAction,
    executing: isExecuting,
  };
}
