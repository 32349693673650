import {
  Control,
  ControlResponse,
  Language,
  Notification,
  Operation,
} from '@yleisradio/areena-types';
import { useState } from 'react';
import { apmRum } from 'services/apm-rum';
import { executeOperation } from 'services/areena-api/fetchers';
import logger from 'services/logger';
import { OperationState } from 'components/Controls/Control/type';
import { ErrorWithNotifications } from 'utils/errorWithNotifications';
import { toast } from 'components/Notifications';
import { getTranslation } from 'services/translations';
import { useUILanguage } from 'hooks/useUILanguage';
import { Action } from './type';
import { isOperator } from 'components/Controls/Control/typeGuards';
import { KeyedMutator } from 'swr';

type ControlErrorMessage = {
  notification: string;
  helpText: string;
};

const getOperationErrorNotification = (
  operation: Operation,
  language: Language
): ControlErrorMessage => {
  if (operation.method === 'DELETE') {
    return {
      notification: getTranslation(language, 'notificationRemoveFailed'),
      helpText: getTranslation(language, 'notificationHelpTextGeneric'),
    };
  } else {
    return {
      notification: getTranslation(language, 'genericError'),
      helpText: getTranslation(language, 'notificationHelpTextGeneric'),
    };
  }
};

function getUIMessage(
  notifications: Notification[] | undefined
): string | undefined {
  if (notifications) {
    const uiMessages = notifications.filter((n) => !!n.uiMessage);
    if (uiMessages.length > 0) {
      return uiMessages.reduce(
        (message, { uiMessage }) =>
          `${message}${message ? ', ' : ''}${uiMessage}`,
        ''
      );
    }
  }
}

function handleOperationNotification(
  operation: Operation,
  language: Language,
  error: unknown
) {
  const messageFromApi =
    error instanceof ErrorWithNotifications
      ? getUIMessage(error.notifications)
      : null;

  if (messageFromApi) {
    toast(messageFromApi, 'error');
  } else {
    const { notification, helpText } = getOperationErrorNotification(
      operation,
      language
    );

    toast(notification, 'error', helpText);
  }
}

type Props = {
  control: Control | null;
  setOperationState: ((newState: OperationState) => void) | undefined;
  mutateControl: KeyedMutator<ControlResponse | null>;
};

export function useOperatorAction({
  control,
  setOperationState,
  mutateControl,
}: Props): Action {
  const [isExecuting, setIsExecuting] = useState(false);
  const language = useUILanguage();

  async function operationAction() {
    if (!isOperator(control)) {
      return;
    }

    if (isExecuting) {
      return;
    }

    const { operation } = control;

    setIsExecuting(true);

    try {
      const response = await executeOperation(operation);
      setOperationState?.('complete');
      mutateControl(response || undefined, {
        revalidate: false,
      });
    } catch (e) {
      logger.error(e);
      if (apmRum && e instanceof Error) {
        apmRum.captureError(e);
      }

      setOperationState?.('failed');
      mutateControl();
      handleOperationNotification(operation, language, e);
    } finally {
      setIsExecuting(false);
    }
  }

  return {
    runAction: operationAction,
    executing: isExecuting,
  };
}
