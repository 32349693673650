import { Card as CardType, ListStyle } from '@yleisradio/areena-types';
import { Link } from 'components/Link';
import React from 'react';
import { getFormattedLabels } from 'utils/card';
import { sanitizeHtmlId } from 'utils/ui-helpers';
import styles from './SearchHorizontalCard.module.scss';
import { CardImage } from './CardImage';
import { CardLabels } from './CardLabels';

type Props = {
  card: CardType;
  listStyle?: ListStyle | undefined;
  cardKey: string;
  sizes: string;
  onClick(): void;
};

const getAspectRatioClassName = (listStyle?: ListStyle | undefined) => {
  switch (listStyle?.image) {
    case '1:1':
      return styles.withAspectRatio1_1;
    case '16:9':
    default:
      return styles.withAspectRatio16_9;
  }
};

export const SearchHorizontalCard: React.FunctionComponent<Props> = ({
  card,
  listStyle,
  cardKey,
  sizes,
  onClick,
}) => {
  const { pointer } = card;

  const formattedLabels = getFormattedLabels(card.labels).filter(
    (l) => !['overlay', 'highlight', 'progress'].includes(l.type)
  );

  const titleId = sanitizeHtmlId(cardKey + 'title');

  return (
    <div className={styles.searchCardHorizontal}>
      {listStyle?.image !== 'none' ? (
        <div className={getAspectRatioClassName(listStyle)}>
          <CardImage
            image={card.image}
            imageStyle={listStyle?.image}
            sizes={sizes}
            cardPresentation={card.presentation}
          />
        </div>
      ) : null}

      <h3 className={styles.title} id={titleId}>
        {pointer ? (
          <Link
            className={styles.searchCardLink}
            pointer={pointer}
            onClick={onClick}
          >
            {card.title}
          </Link>
        ) : (
          card.title
        )}
      </h3>

      <div className={styles.labels}>
        <CardLabels labels={formattedLabels} />
      </div>
    </div>
  );
};
